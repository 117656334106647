import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoanApplicationState } from "store/types/slice/ApplyForLoanSlice";

const initialState: LoanApplicationState = {
  applicantName: "",
  phoneNumber: "",
};

const loanApplicationSlice = createSlice({
  name: "loanApplication",
  initialState,
  reducers: {
    setApplicantName: (state, action: PayloadAction<string>) => {
      state.applicantName = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
  },
});

export const { setApplicantName, setPhoneNumber } =
  loanApplicationSlice.actions;
export default loanApplicationSlice.reducer;
export const selectLoanApplication = (state: {
  loanApplication: LoanApplicationState;
}) => state.loanApplication;
