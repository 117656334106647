/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLoanType } from "api/SelectLoanType";
import { API_ENDPOINTS } from "Constant";
import { LoanTypeState, LoanType } from "store/types/slice/LoanTypeSlice";

export const fetchLoanTypes = createAsyncThunk<
  LoanType[],
  void,
  { rejectValue: string }
>("loanType/fetchLoanTypes", async (_, { rejectWithValue }) => {
  try {
    const data = await getLoanType(API_ENDPOINTS.get_loan_type);
    return data.loans;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const initialState: LoanTypeState = {
  loanTypes: [],
  status: "idle",
  error: null,
};

const loanTypeSlice = createSlice({
  name: "loanType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoanTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loanTypes = action.payload;
      })
      .addCase(fetchLoanTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default loanTypeSlice.reducer;
