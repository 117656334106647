/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useRef } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setLoanStatusData, setTransactId } from "store/slices/authSlice";
import { IoIosArrowBack, IoIosHelpCircle } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  LoanStatusApiResponse,
  loanStatusPostApi,
  loanUpdatePostApi,
  loanUpdateLogCheck,
} from "api/LoanStatus";
import { RootState } from "store/types/store";
import {
  BottomModalProps,
  LoansData,
  SureModalProps,
} from "components/LoanStatus/types";
import { API_ENDPOINTS, LOGS_CHECK, toastErrorMessages } from "Constant";
import Loader from "components/Loader/Loader";
import right_holder from "assets/images/right_holder.png";
import "components/LoanStatus/loanstatus.css";
import "components/OffersDetailsShow/OffersDetailsShow.css";

let newTab_new: Window | null;
let time_interval: string | number | NodeJS.Timeout | undefined;

const BottomModal = ({
  setIsLoading,
  isOpen,
  onClose,
  onProceed,
  forCloseAmt,
  missedEmiAmt,
  mapData,
  setNewSetMapDataLoan,
}: BottomModalProps) => {
  onProceed;
  mapData;
  const navigate = useNavigate();
  const {
    clientID,
    apiKey,
    agentID,
    loan_status_json,
    phoneNumber,
    transact_id,
    tx_id,
    ctn_num,
    token,
  } = useSelector((state: RootState) => state.auth);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [enterAmount, setEnterAmount] = useState<number | "">("");
  const [updateStatus, setupdateStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectPaymentOption, setSelectPaymentOption] = useState(true);
  const handleOptionChange = (option: React.SetStateAction<string>) => {
    setSelectedOption(option);
  };

  const handleEnterAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value ? Number(value) : "";
    setEnterAmount(numericValue);
  };

  const handleProceed = () => {
    if (selectedOption === "partPayment") {
      if (!enterAmount || Number(enterAmount) <= 0) {
        setErrorMessage("Please enter a valid pre-part payment amount");
      } else {
        setErrorMessage("");
        setupdateStatus(true);
      }
    }

    if (selectedOption === "foreclosure" || selectedOption === "missedEMI") {
      setupdateStatus(true);
    }
  };

  const TimeStart = (post_postfulliment_json: LoanStatusApiResponse) => {
    time_interval = setInterval(() => {
      calltimerstart(post_postfulliment_json);
    }, 10000);
  };

  const closeTab = () => {
    clearInterval(time_interval);
    newTab_new?.postMessage("Close tab", "*");
    newTab_new?.close();
    navigate("/LoanStatus");
  };

  const calltimerstart = async (
    post_postfulliment_json: LoanStatusApiResponse,
  ) => {
    const requestBodyData = {
      action: "on_update",
      transaction_id: post_postfulliment_json?.context?.transaction_id,
      subscriber_id: post_postfulliment_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [post_postfulliment_json?.context?.bpp_id],
    };

    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num,
      Authorization: `Bearer ${token}`,
    };
    loanUpdateLogCheck(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        if (response != null) {
          if (response.data != null) {
            const paymentdata = response.data.message.order.payments;
            const paymentdata_two =
              post_postfulliment_json.message.order.payments;

            const currentTimeStamp = response.data.context.timestamp;
            const prevTimeStamp = post_postfulliment_json.context.timestamp;
            setNewSetMapDataLoan(response.data);
            if (paymentdata.length > 0) {
              for (let i = 0; i < paymentdata.length; i++) {
                for (let j = 0; j < paymentdata_two.length; j++) {
                  if (paymentdata[i].time != null) {
                    if (paymentdata[i].time?.label === "FORECLOSURE") {
                      if (paymentdata[i].type === "POST_FULFILLMENT") {
                        if (
                          paymentdata_two[j].id === paymentdata[i].id &&
                          paymentdata[i].status === "PAID"
                        ) {
                          if (currentTimeStamp > prevTimeStamp) {
                            if (!newTab_new?.closed) {
                              closeTab();
                            } else {
                              clearInterval(time_interval);
                              navigate("/LoanStatus");
                            }
                          }
                        }
                      }
                    } else if (
                      paymentdata[i].time?.label === "PRE_PART_PAYMENT"
                    ) {
                      if (paymentdata[i].type === "POST_FULFILLMENT") {
                        if (
                          paymentdata_two[j].id === paymentdata[i].id &&
                          paymentdata[i].status === "PAID"
                        ) {
                          if (currentTimeStamp > prevTimeStamp) {
                            if (!newTab_new?.closed) {
                              closeTab();
                            } else {
                              clearInterval(time_interval);
                              navigate("/LoanStatus");
                            }
                          }
                        }
                      }
                    } else if (
                      paymentdata[i].time?.label === "MISSED_EMI_PAYMENT"
                    ) {
                      if (paymentdata[i].type === "POST_FULFILLMENT") {
                        if (
                          paymentdata_two[j].id === paymentdata[i].id &&
                          paymentdata[i].status === "PAID"
                        ) {
                          if (currentTimeStamp > prevTimeStamp) {
                            if (!newTab_new?.closed) {
                              closeTab();
                            } else {
                              clearInterval(time_interval);
                              navigate("/LoanStatus");
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        toast.error(error);
      },
    );
  };

  const handleLoanUpdate = async () => {
    setIsLoading(true);
    const obj = {
      updateType:
        selectedOption === "foreclosure"
          ? "FORECLOSURE"
          : selectedOption === "partPayment"
            ? "PRE_PART_PAYMENT"
            : "MISSED_EMI_PAYMENT",
      on_status: JSON.parse(loan_status_json),
      payment_params_amount:
        selectedOption === "foreclosure"
          ? forCloseAmt
          : selectedOption === "partPayment"
            ? enterAmount
            : missedEmiAmt,
      payment_params_currency: "INR",
    };
    const phone = phoneNumber;
    const txn_id = transact_id;
    const headers = {
      txn_id: txn_id,
      contact_number: phone,
      Authorization: `Bearer ${token}`,
    };

    loanUpdatePostApi(
      API_ENDPOINTS.laon_update,
      obj,
      headers,
      (response) => {
        if (response != null) {
          if (response.data != null) {
            const message = response.data.message;
            if (message) {
              const itemsList = response.data.message.order.payments;
              if (itemsList && itemsList.length > 0) {
                itemsList.forEach((jsonObject) => {
                  const url = jsonObject.url || "";
                  if (url) {
                    setIsLoading(false);
                    TimeStart(response.data);
                    newTab_new = window.open(
                      url,
                      "_blank",
                      "width=300,height=500",
                    );
                    if (newTab_new) {
                      setTimeout(function () {
                        setupdateStatus(false);
                        setSelectPaymentOption(false);
                      }, 3000);
                    } else {
                      newTab_new = window.location.href = url as any;
                    }
                  }
                });
              }
            } else {
              toast.error(toastErrorMessages.somethingWentWrongErrorMessage);
            }
          }
        }
      },
      (error) => {
        toast.error(toastErrorMessages.somethingWentWrongErrorMessage || error);
        setTimeout(() => {
          navigate(
            `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
          );
        }, 3000);
      },
    );
  };

  useEffect(() => {
    if (updateStatus && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [updateStatus]);

  const SureModel = ({
    setupdateStatus,
    handleLoanUpdate,
    enterAmount,
    forCloseAmt,
    selectedOption,
  }: SureModalProps) => {
    return (
      <div
        className=""
        ref={modalRef}
        style={{ boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px" }}
      >
        <div
          style={{
            background: "#fff",
            zIndex: "99999999",
            position: "relative",
            padding: "0px 0px 10%",
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "65px",
                height: "80px",
                margin: "auto",
              }}
            >
              <img className="w-100" src={right_holder} alt="Right Holder" />
            </div>
            <h3>
              {selectedOption === "foreclosure"
                ? "FORCLOSURE"
                : selectedOption === "partPayment"
                  ? "PRE PART PAYMENT"
                  : "MISSED EMI AMOUNT"}
            </h3>
            <div>
              <p>
                Are you sure want to pay{" "}
                <b>
                  {selectedOption === "foreclosure"
                    ? forCloseAmt
                    : selectedOption === "partPayment"
                      ? enterAmount
                      : missedEmiAmt}
                </b>
              </p>
            </div>
            <div>
              {selectedOption === "foreclosure" ||
              selectedOption === "missedEMI" ||
              selectedOption === "partPayment" ? (
                <p>This may attract charges as per lenders terms.</p>
              ) : null}
            </div>
          </div>

          <div
            className="bottom-btn"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              style={{
                width: "30%",
                backgroundColor: "#3946aa",
                color: "white",
                border: "none",
                borderRadius: "10px",
                padding: "10px 20px",
                cursor: "pointer",
              }}
              className="btn btn-primary btn-sm"
              onClick={handleLoanUpdate}
            >
              YES
            </button>

            <button
              onClick={() => setupdateStatus(false)}
              style={{
                marginLeft: "10px",
                width: "30%",
                backgroundColor: "#3946aa",
                color: "white",
                border: "none",
                borderRadius: "10px",
                padding: "10px 20px",
                cursor: "pointer",
              }}
              className="btn btn-danger btn-sm "
            >
              NO
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`bottom-modal ${isOpen ? "open" : ""}`}
      style={{
        background: "white",
        margin: "0%",
      }}
    >
      <div className="overlay" onClick={onClose}></div>
      {updateStatus ? (
        <SureModel
          setupdateStatus={setupdateStatus}
          handleLoanUpdate={handleLoanUpdate}
          enterAmount={enterAmount}
          forCloseAmt={forCloseAmt}
          selectedOption={selectedOption}
          missedEmiAmt={missedEmiAmt}
        />
      ) : (
        <div>
          {selectPaymentOption ? (
            <div
              className="modal-content"
              style={{
                padding: "15px 50px 10px 50px",
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Select Payment Option</h3>
                <AiOutlineCloseCircle
                  style={{ cursor: "pointer", marginTop: "5px" }}
                  onClick={onClose}
                  size={25}
                />
              </div>
              <div className="d-flex justify-content-start align-items-center gap-3">
                <input
                  type="radio"
                  name="paymentOption"
                  value="foreclosure"
                  checked={selectedOption === "foreclosure"}
                  onChange={() => handleOptionChange("foreclosure")}
                />
                <div>
                  <p className="p-0 m-0 text-bold">Foreclosure</p>
                  <div style={{ color: "gray", fontSize: "11px" }}>
                    Describe, When the buyer initiates the foreclosure intent
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center gap-3">
                <input
                  type="radio"
                  name="paymentOption"
                  value="partPayment"
                  checked={selectedOption === "partPayment"}
                  onChange={() => handleOptionChange("partPayment")}
                />
                <div>
                  <p className="p-0 m-0 text-bold">Pre-part payment</p>
                  <div style={{ color: "gray", fontSize: "11px" }}>
                    Describe, When the buyer initiates the pre part intent
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center gap-3">
                <input
                  type="radio"
                  name="paymentOption"
                  value="missedEMI"
                  checked={selectedOption === "missedEMI"}
                  onChange={() => handleOptionChange("missedEMI")}
                />
                <div>
                  <p className="p-0 m-0 text-bold">Missed EMI Payment</p>
                  <div style={{ color: "gray", fontSize: "11px" }}>
                    Describe, When the buyer missed to make a scheduled
                    installment payment
                  </div>
                </div>
              </div>
              {selectedOption === "partPayment" ? (
                <div>
                  <div
                    className="content"
                    style={{ padding: "10px 20px 0 20px" }}
                  >
                    <input
                      type="number"
                      value={enterAmount}
                      placeholder="Enter Amount"
                      onChange={handleEnterAmountChange}
                      style={{
                        fontSize: "17px",
                        padding: "10px",

                        borderRadius: "10px",
                        border: "1px solid #3946aa",
                        width: "100%",
                      }}
                    />
                  </div>
                  {errorMessage && (
                    <div
                      style={{
                        color: "red",
                        marginLeft: "20px",
                        fontSize: "10px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                </div>
              ) : null}
              <button
                onClick={handleProceed}
                style={{
                  width: "100%",
                  backgroundColor: "#3946aa",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                Proceed
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const LoanStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tx_id, ctn_num, token, myloanIndex, loansData } = useSelector(
    (state: RootState) => state.auth,
  );

  const [mapData, setMapDataLoan] = useState<LoanStatusApiResponse | null>(
    null,
  );
  const [loanstatus, setLoanStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forCloseAmt, setforCloseAmt] = useState("");
  const [missedEmiAmt, setmissedEmiAmt] = useState(0);
  const [, setDownloadAbleLink] = useState("");
  const [, setDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInstallments, setShowInstallments] = useState(false);
  const [showGroDetails, setShowGroDetails] = useState(false);
  const [showLspDetails, setShowLspDetails] = useState(false);

  const toggleLspDetails = () => {
    setShowLspDetails(!showLspDetails);
  };

  const toggleGroDetails = () => {
    setShowGroDetails(!showGroDetails);
  };

  const toggleInstallments = () => {
    setShowInstallments(!showInstallments);
  };

  const setNewSetMapDataLoan = (
    data: React.SetStateAction<LoanStatusApiResponse | null>,
  ) => {
    setMapDataLoan(data);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleProceed = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const selectedLoanIndex = myloanIndex || 0;
    const loans: LoansData[] = JSON.parse(loansData);
    const single_selected_loan = loans[selectedLoanIndex];
    setIsLoading(false);

    const keyToDocuments = "url";
    dispatch(setTransactId(single_selected_loan.context.transaction_id));
    const principalBreakup =
      single_selected_loan.message.order.quote?.breakup.find(
        (x) => x.title === "PRINCIPAL",
      );
    setforCloseAmt(principalBreakup?.price.value ?? "0");
    const missedEmiAmount =
      single_selected_loan.message.order.payments?.[1]?.params?.amount;
    setmissedEmiAmt(missedEmiAmount ?? 0);
    const documentsList = single_selected_loan.message?.order?.documents || [];
    if (documentsList.length > 0) {
      documentsList.forEach((jsonObject) => {
        if (jsonObject[keyToDocuments]) {
          const downloadLink = jsonObject["url"];
          setDownloadAbleLink(downloadLink);
        }
      });
    }

    getStatusData(single_selected_loan);
  }, [loansData?.length, myloanIndex]);

  const downloadPdf = async (downloadAbleLink: string | URL | Request) => {
    try {
      setDownloading(true);
      const response = await fetch(downloadAbleLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "loan_statement.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloading(false);
    } catch (error: any) {
      toast.error(error);
      setDownloading(false);
    }
  };

  const getStatusData = async (responce_data: LoansData) => {
    setIsLoading(true);
    const headers = {
      txn_id: tx_id,
      Accept: "*/*",
      contact_number: ctn_num,
      Authorization: `Bearer ${token}`,
    };

    const pay_data = {
      on_confirm: responce_data,
    };

    loanStatusPostApi(
      API_ENDPOINTS.laon_status,
      pay_data,
      headers,
      (response) => {
        setIsLoading(false);
        if (response != null) {
          if (response.data != null) {
            setMapDataLoan(response.data);
            if (response?.data?.message !== null) {
              dispatch(setLoanStatusData(JSON.stringify(response.data)));
              const itemsList = response?.data?.message.order.fulfillments;
              if (itemsList.length > 0) {
                for (let i = 0; i < itemsList.length; i++) {
                  setLoanStatus(itemsList[i].state.descriptor.code);
                }
              }
            }
          } else {
            alert("Something went wrong..");
          }
        } else {
          alert("Something went wrong..");
        }
      },
      (error) => {
        toast.error(error);
      },
    );
  };

  const handleTickets = () => {
    navigate("/Tickets");
  };

  return (
    <div>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div
            onClick={handleTickets}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "5px",
              textAlign: "right",
            }}
          >
            <span style={{ fontSize: "18px", color: "white" }}>Need Help</span>
            <IoIosHelpCircle
              style={{ marginLeft: "5px", fontSize: "20px", color: "white" }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div style={{ backgroundColor: "white" }}>
            <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  height: "150px",
                  margin: "auto",
                }}
              >
                <img className="w-100" src={right_holder} alt="Right Holder" />
              </div>
            </div>

            <div
              style={{
                margin: "10px 0",
                padding: "10px",
                backgroundColor: "#E0E0E0",
                textAlign: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Your loan status is {loanstatus}
                </div>
              </div>
            </div>

            <div style={{ padding: "20px" }}>
              <div className="loan_details">
                <h2
                  style={{
                    fontSize: "17px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  LOAN DETAILS
                </h2>
              </div>

              {/* Application Id */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Application Id
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: "700",
                        }}
                      >
                        {mapData?.message?.order?.quote?.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Principle */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Principal Amount
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {
                          mapData?.message?.order?.quote?.breakup?.find(
                            (item) => item?.title == "PRINCIPAL",
                          )?.price?.value
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Interest Rate */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Interest Rate
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {mapData?.message?.order?.items?.map((ele) =>
                          ele?.tags?.map(
                            (val) =>
                              val?.list?.find(
                                (element) =>
                                  element?.descriptor?.code === "INTEREST_RATE",
                              )?.value,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Processing Fee */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Processing Fee
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {
                          mapData?.message?.order?.quote?.breakup?.find(
                            (item) => item?.title == "PROCESSING_FEE",
                          )?.price?.value
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Foreclosure Penalty */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Foreclosure Penalty
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {mapData?.message?.order?.items?.map((ele) =>
                          ele?.tags?.map(
                            (val) =>
                              val?.list?.find(
                                (element) =>
                                  element?.descriptor?.code ===
                                  "FORECLOSURE_FEE",
                              )?.value,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Delay payments penalty */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Delay payments penalty
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {mapData?.message?.order?.items?.map((ele) =>
                          ele?.tags?.map(
                            (val) =>
                              val?.list?.find(
                                (element) =>
                                  element?.descriptor?.code ===
                                  "DELAY_PENALTY_FEE",
                              )?.value,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Other penal charges */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Other penal charges
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {mapData?.message?.order?.items?.map((ele) =>
                          ele?.tags?.map(
                            (val) =>
                              val?.list?.find(
                                (element) =>
                                  element?.descriptor?.code ===
                                  "OTHER_PENALTY_FEE",
                              )?.value,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Other Upfront Charges */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Other Upfront Charges
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {
                          mapData?.message?.order?.quote?.breakup?.find(
                            (item) => item?.title === "OTHER_UPFRONT_CHARGES",
                          )?.price?.value
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Insurance Charges */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Insurance Charges
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {
                          mapData?.message?.order?.quote?.breakup?.find(
                            (item) => item?.title == "INSURANCE_CHARGES",
                          )?.price?.value
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Other Charges */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Other Charges
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {
                          mapData?.message?.order?.quote?.breakup?.find(
                            (item) => item?.title == "OTHER_CHARGES",
                          )?.price?.value
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Net Disbursed Amount */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Net Disbursed Amount
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">
                        {
                          mapData?.message?.order?.quote?.breakup?.find(
                            (item) => item?.title == "NET_DISBURSED_AMOUNT",
                          )?.price?.value
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Loan Status */}
              <div className="loan_data">
                <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                  <div className="color-grey font-bold font-12">
                    Loan Status
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <div style={{ padding: 8 }}>
                      <div className="font-bold">{loanstatus}</div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div>
                <button
                  className="mt-2 mb-2"
                  onClick={() => downloadPdf("")}
                  style={{
                    backgroundColor: "#3946aa",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "0 10px",
                    cursor: "pointer",
                  }}
                >
                  Loan Detail PDF
                </button>
              </div>
              <hr />
            </div>

            <div style={{ padding: "20px" }}>
              <div
                className="loan_details"
                onClick={toggleGroDetails}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    fontSize: "17px",
                    color: "#000000",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  GRO DETAILS
                </h2>
                {showGroDetails ? <FaChevronUp /> : <FaChevronDown />}
              </div>

              {showGroDetails && (
                <div>
                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Gro name
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[0]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Gro email
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[1]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Gro contact number
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[2]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Gro Designation
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[3]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Gro Address
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[4]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Customer support link
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[5]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Customer support contact number
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[6]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Customer support email
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[0]
                                ?.list[7]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              )}
            </div>

            <div style={{ padding: "20px" }}>
              <div
                className="loan_details"
                onClick={toggleLspDetails}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    fontSize: "17px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  LSP DETAILS
                </h2>
                {showLspDetails ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showLspDetails && (
                <div>
                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Lsp name
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[1]
                                ?.list[0]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Lsp email
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[1]
                                ?.list[1]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Lsp contact number
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[1]
                                ?.list[2]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="loan_data">
                    <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                      <div className="color-grey font-bold font-12">
                        Lsp Address
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ padding: 8 }}>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: "700",
                            }}
                          >
                            {
                              mapData?.message?.order?.provider?.tags[1]
                                ?.list[3]?.value
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            <div style={{ padding: "20px", paddingBottom: "90px" }}>
              <div
                className="loan_details"
                onClick={toggleInstallments}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    fontSize: "17px",
                    color: "#000000",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  INSTALLMENTS
                </h2>
                {showInstallments ? <FaChevronUp /> : <FaChevronDown />}
              </div>

              {showInstallments && (
                <div style={{ padding: "20px" }}>
                  {mapData?.message?.order?.payments
                    .filter((data) => {
                      if (data.id.length < 6) {
                        return data;
                      }

                      return null;
                    })
                    .map((data, index) => (
                      <div key={index}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              flex: 1,
                              textAlign: "left",
                              margin: "10px 10px 0 10px",
                            }}
                          >
                            <span style={{ fontSize: 14 }}>
                              Amount: {data?.params?.amount}
                            </span>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              textAlign: "left",
                              margin: "10px 10px 0 10px",
                            }}
                          >
                            <span style={{ fontSize: 14 }}>
                              Status: {data?.status}
                            </span>
                          </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              flex: 1,
                              textAlign: "left",
                              margin: "10px 10px 0 10px",
                            }}
                          >
                            <span style={{ fontSize: 14 }}>
                              Start Date:{" "}
                              {moment(data?.time?.range?.start).format(
                                "DD-MM-YYYY",
                              )}
                            </span>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              textAlign: "left",
                              margin: "10px 10px 0 10px",
                            }}
                          >
                            <span style={{ fontSize: 14 }}>
                              End Date:{" "}
                              {moment(data?.time?.range?.end).format(
                                "DD-MM-YYYY",
                              )}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="bottom-btn">
        <div>
          {isModalOpen ? (
            <BottomModal
              setIsLoading={setIsLoading}
              mapData={mapData}
              isOpen={isModalOpen}
              onClose={closeModal}
              onProceed={handleProceed}
              forCloseAmt={forCloseAmt}
              missedEmiAmt={missedEmiAmt}
              setNewSetMapDataLoan={setNewSetMapDataLoan}
            />
          ) : (
            loanstatus !== "" && (
              <button
                onClick={openModal}
                style={{
                  width: "100%",
                  backgroundColor: "#3946aa",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Payment Options
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanStatus;
