/* eslint-disable no-unused-vars */
import { Secured } from "utils/HelperFunctions/api";
import axios from "axios";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

export interface Order {
  provider: Provider;
  items: Item[];
  quote: Quote;
}

interface Quote {
  price: {
    currency: string;
    value: string;
  };
  breakup: QuoteBreakup[];
  ttl: string;
  id: string;
}

interface QuoteBreakup {
  title: string;
  price: {
    currency: string;
    value: string;
  };
}

interface Provider {
  id: string;
  descriptor: Descriptor;
  tags: Tag[];
}

interface Tag {
  descriptor: Descriptor;
  list: { descriptor: Descriptor; value: string }[];
  display: boolean;
}

interface Descriptor {
  name: string;
  short_desc?: string;
  long_desc?: string;
  images?: Image[];
}

interface Image {
  size_type: string;
  url: string;
}

interface Item {
  id: string;
  descriptor: {
    name: string;
    code: string;
  };
  price: {
    currency: string;
    value: string;
  };
  tags: ItemTag[];
  xinput: {
    head: {
      descriptor: {
        name: string;
      };
      index: {
        min: number;
        cur: number;
        max: number;
      };
      headings: string[];
    };
    form: {
      id: string;
      mime_type: string;
      url: string;
      resubmit: boolean;
      multiple_sumbissions: boolean;
    };
    form_response: {
      status: string;
      submission_id: string;
    };
    required: boolean;
  };
}

interface ItemTag {
  descriptor: {
    name: string;
    code: string;
  };
  list: Array<{
    descriptor: {
      name: string;
      code: string;
      short_desc?: string;
    };
    value: string;
  }>;
}

export interface Context {
  domain: string;
  action: string;
  bap_id: string;
  bap_uri: string;
  bpp_id: string;
  bpp_uri: string;
  transaction_id: string;
  message_id: string;
  timestamp: string;
  ttl: string;
  location: Location;
  version: string;
}

interface Location {
  country: CodeValue;
  city: CodeValue;
}
interface CodeValue {
  code: string;
}

export interface ApiResponse {
  context: Context;
  message: {
    order: Order;
  };
}

export const emandateLogsCheck = async (
  url: string,
  data: {
    action: string;
    transaction_id: string;
    subscriber_id: string;
    record_Range: string;
    type: string;
    sellers: string[];
  },
  headers: {
    "Content-Type": string;
    txn_id: string | null;
    contact_number: string | null;
    Authorization: string;
  },
  success: (response: { status: number; data: ApiResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await axios.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};

export const emandatePostApi = async (
  url: string,
  data: {
    postEnach: any;
    submission_id: string;
  },
  headers: {
    "Content-Type": string;
    txn_id: string | null;
    contact_number: string | null;
    Authorization: string;
  },
  success: (response: { status: number; data: ApiResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
