/* eslint-disable no-console */
import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNewDisbusmentData } from "store/slices/authSlice";
import { emandateLogsCheck, emandatePostApi, ApiResponse } from "api/Emandate";
import {
  API_ENDPOINTS,
  LOGS_CHECK,
  API_URL,
  CONFIRM_DISBUSMENT,
  STAGING_URL,
  STAGING_CONFIRM_DISBUSMENT,
} from "Constant";
import { RootState } from "store/types/store";
import tick from "assets/images/tick.png";
import "components/OffersDetailsShow/OffersDetailsShow.css";

let newTab_new: Window | null;
let time_interval: string | number | NodeJS.Timeout | undefined;
type FinalNewDisbursementJson = ApiResponse;
const Emandate = () => {
  const dispatch = useDispatch();
  const {
    clientID,
    apiKey,
    agentID,
    tx_id,
    ctn_num,
    token,
    add_bank_json,
    applyPhoneNumber,
  } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const TimeStart = (final_new_disbusment_json: FinalNewDisbursementJson) => {
    time_interval = setInterval(() => {
      calltimerstart(final_new_disbusment_json);
    }, 5000);
  };

  const closeTab = () => {
    clearInterval(time_interval);
    setTimeout(() => {
      if (window.location.href.includes("localhost")) {
        window.open(CONFIRM_DISBUSMENT, "_blank");
      } else if (window.location.href.includes(STAGING_URL)) {
        window.open(STAGING_CONFIRM_DISBUSMENT);
      } else {
        window.location.href.includes("paynearby-preprod")
          ? window.open(`${API_URL}/ConfirmDisbusment`)
          : window.open(`${API_URL}/ConfirmDisbusment`);
      }
    }, 1000);
  };

  const calltimerstart = async (
    final_new_disbusment_json: FinalNewDisbursementJson,
  ) => {
    const requestBodyData = {
      action: "on_status",
      transaction_id: final_new_disbusment_json?.context?.transaction_id,
      subscriber_id: final_new_disbusment_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [final_new_disbusment_json?.context?.bpp_id],
    };

    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };

    emandateLogsCheck(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        console.error("Success", response);
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const status = jsonObject.xinput.form_response.status;
                  if (status === "APPROVED") {
                    setStatus(status);
                    const prevTimeStamp =
                      final_new_disbusment_json.context.timestamp;
                    const currentTimeStamp = response.data.context.timestamp;
                    const prevDateTime = new Date(prevTimeStamp);
                    const currDateTime = new Date(currentTimeStamp);
                    if (currDateTime > prevDateTime) {
                      if (!newTab_new?.closed) {
                        closeTab();
                      } else {
                        closeTab();
                      }
                    }
                  } else {
                    setStatus(status);
                    toast.error(
                      "Unable to proceed further due to E-SIGN failure",
                    );
                    clearInterval(time_interval);
                    setTimeout(() => {
                      return navigate(
                        `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
                      );
                    }, 3000);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        setStatus("");
        toast.error(error);
      },
    );
  };

  const handleSubmitNew = async () => {
    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };

    const pay_data = {
      postEnach: JSON.parse(add_bank_json),
      submission_id: "",
    };
    emandatePostApi(
      API_ENDPOINTS.finalagreement,
      pay_data,
      headers,
      (response) => {
        dispatch(setNewDisbusmentData(JSON.stringify(response.data)));
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const url = jsonObject.xinput.form.url;
                  if (url && url.length > 0) {
                    TimeStart(response.data);
                    newTab_new = window.open(url, "_blank");
                    if (newTab_new) {
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setTimeout(function () {}, 3000);
                    }
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        console.error("Error:", error);
        setStatus("REJECTED");
      },
    );
  };

  return (
    <div>
      <div
        style={{
          backgroundColor:
            status === "APPROVED"
              ? "#4CAF50"
              : status === "REJECTED" || status === "PENDING" || status === ""
                ? "white"
                : "white",
          padding: "20px",
          height: "100vh",
          textAlignLast: "center",
          paddingTop: "150px",
        }}
      >
        {status === "APPROVED" && (
          <img src={tick} alt="Tick" width="100" height="100" />
        )}

        <div
          style={{
            color:
              status === "APPROVED"
                ? "white"
                : status === "REJECTED" || status === "PENDING" || status === ""
                  ? "black"
                  : "black",
            fontSize: "17px",
            fontWeight: "500",
            marginTop: "10px",
          }}
        >
          {(status === "" && <>Click Proceed for e-sign execution ...</>) ||
            (status === "PENDING" && (
              <>Unable to proceed further due to E-SIGN failure.</>
            )) ||
            (status === "REJECTED" && (
              <>Unable to proceed further due to E-SIGN failure.</>
            )) ||
            (status === "APPROVED" && <>Signed Successfully</>)}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "white",
            marginTop: "10px",
          }}
        >
          {status === "" ||
            (status === "APPROVED" && <>Do not close the window.</>)}{" "}
          {status === "APPROVED" && (
            <>You will be redirect to e-sign execution.</>
          )}
        </div>
        {status !== "APPROVED" && (
          <div style={{ margin: "10px" }} className="bottom-btn">
            <button
              onClick={() => handleSubmitNew()}
              style={{
                backgroundColor: "#3946aa",
                color: "white",
                border: "none",
                borderRadius: "10px",
                padding: "10px 20px",
                width: "100%",
              }}
            >
              Proceed to E-sign Execution
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Emandate;
