import React from "react";
import { ColorRing } from "react-loader-spinner";

const Loader = (props: {
  KYCMessage?:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  ENACHMessage?:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <div>
      <div style={{ position: "relative", height: "50vh" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#3946aa", "#3946aa", "#3946aa", "#3946aa", "#3946aa"]}
          />
          <p>Please wait...</p>
          {props?.KYCMessage ? (
            <p className="pt-1">{props?.KYCMessage}</p>
          ) : null}
          {props?.ENACHMessage ? (
            <p className="pt-1">{props?.ENACHMessage}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Loader;
