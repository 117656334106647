import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { ColorRing } from "react-loader-spinner";
import {
  fetchAAList,
  toggleItemSelection,
  setLoading,
} from "store/slices/AASlice";
import { RootState } from "store/types/store";
import { AppDispatch } from "store/store";
import Loader from "components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import "components/AAListView/AA.css";

const AAListView = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { url } = useSelector((state: RootState) => state.auth);
  const { aaListPrint, isLoading, selectedItem } = useSelector(
    (state: RootState) => state.aa
  );

  useEffect(() => {
    dispatch(fetchAAList());
  }, [dispatch]);

  const handleIndividualInfo = () => {
    const redirectUrl = url;
    window.location.href = redirectUrl as string;
    dispatch(setLoading(true));
  };

  return (
    <div>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>AA List</span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ backgroundColor: "#ffffff", padding: "20px" }}>
          {aaListPrint.length > 0 ? (
            aaListPrint.map((item, index) => (
              <div className="mx-3 mt-2" key={index}>
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ width: "50px", height: "40px" }}>
                    <img className="w-100" src={item.logo} alt="Bank Logo" />
                  </div>
                  <span>{item.name}</span>
                  <input
                    type="radio"
                    checked={selectedItem === item.id}
                    onChange={() => dispatch(toggleItemSelection(item.id))}
                  />
                </div>
              </div>
            ))
          ) : (
            <div style={{ position: "relative", height: "50vh" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#3946aa",
                    "#3946aa",
                    "#3946aa",
                    "#3946aa",
                    "#3946aa",
                  ]}
                />
                <p>Please wait...</p>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedItem && (
        <div style={{ margin: "10px" }} className="bottom-btn">
          <button
            onClick={handleIndividualInfo}
            style={{
              backgroundColor: "#3946aa",
              color: "white",
              border: "none",
              borderRadius: "10px",
              padding: "10px 20px",
              cursor: "pointer",
              width: "100%",
            }}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default AAListView;
