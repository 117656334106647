/* eslint-disable no-console */
import { useRef } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import {
  Context,
  Order,
  offerDetailsLogCheckPostApi,
  offerDetailsPostApi,
  selectOfferPostApi,
} from "api/OfferDetailsShow";
import { API_ENDPOINTS, LOGS_CHECK, toastErrorMessages } from "Constant";
import { KYCMessage } from "constant/AppConstant";
import { RootState } from "store/types/store";
import {
  setMinLoanAmount,
  setOffersMapData,
  setBankOfferData,
  setKycUrlData,
  setBankNameData,
  setBppData,
  selectBankOfferData,
} from "store/slices/authSlice";
import Loader from "components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import "components/OffersDetailsShow/OffersDetailsShow.css";

let newTab_new: Window | null;
let time_interval: string | number | NodeJS.Timeout | undefined;
type OffersMapJson = {
  context: Context;
  message: {
    order: Order;
  };
};
const OffersDetailsShow = () => {
  const {
    clientID,
    apiKey,
    agentID,
    tx_id,
    ctn_num,
    token,
    bpp_id,
    // offers_map_json,
    bankOfferData,
    kyc_url,
    index,
    applyPhoneNumber,
  } = useSelector((state: RootState) => state.auth);
  const bankOfferDataUpdated = useSelector(selectBankOfferData);
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loanAmount, setLoanAmount] = useState("");
  const [offer_jsonobejct, setoffersobject] = useState({});
  const [principalAmount, setprincipalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [minLoanAmt, setMinLoanAmt] = useState("");
  const [gotNewOfferData, setGotNewOfferData] = useState(false);
  const [loan_offersmodel, setLoanOffersModel] = useState({
    bankName: "",
    principle_amount: "",
    processing_fee: "",
    processing_fee_title: "",
    interest: "",
    interest_title: "",
    other_upfront_charges: "",
    other_upfront_charges_title: "",
    insurance_charges: "",
    insurance_charges_title: "",
    net_disbursed_amt: "",
    net_disbursed_amt_title: "",
    other_charges: "",
    other_charges_title: "",
    imageUrl: "",
    loanAmount: "",
    firstHeading: "",
    interestRateShortDec: "",
    interestRate: "",
    loanTerms: "",
    loanTermsShortDec: "",
    interestRateType: "",
    interestRateTypeValue: "",
    applicationFees: "",
    applicationFeesValue: "",
    foreclosurePenalty: "",
    foreclosurePenaltyValue: "",
    interestRateConversionCharges: "",
    interestRateConversionChargesValue: "",
    delayedPaymentsPenalty: "",
    delayedPaymentsPenaltyValue: "",
    otherPenalCharges: "",
    otherPenalChargesValue: "",
    termsConditions: "",
    termsConditionsValue: "",
    annualPercentageRate: "",
    annualPercentageRateValue: "",
    repaymentFrequency: "",
    repaymentFrequencyValue: "",
    numberOfInstallmentsOfRepayment: "",
    numberOfInstallmentsOfRepaymentValue: "",
    coolOffPeriod: "",
    coolOffPeriodValue: "",
    installmentAmount: "",
    installmentAmountValue: "",
    secondHeading: "",
    groName: "",
    groNameValue: "",
    groEmail: "",
    groEmailValue: "",
    groContactNumber: "",
    groContactNumberValue: "",
    groDesignation: "",
    groDesignationValue: "",
    groAddress: "",
    groAddressValue: "",
    customerSupportLink: "",
    customerSupportLinkValue: "",
    customerSupportContactNumber: "",
    customerSupportContactNumberValue: "",
    customerSupportEmail: "",
    customerSupportEmailValue: "",
    thirdHeading: "",
    lspName: "",
    lspNameValue: "",
    lspEmail: "",
    lspEmailValue: "",
    lspContactNumber: "",
    lspContactNumberValue: "",
    lspAddress: "",
    lspAddressValue: "",
    null: "",
  });
  const [shouldCallDG, setShouldCallDG] = useState(false);

  useEffect(() => {
    if (shouldCallDG) {
      getDGLockerWebView_two();
      setShouldCallDG(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principalAmount, shouldCallDG]);

  const toggleModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showModal, gotNewOfferData]);

  const handleLoanRequest = () => {
    toggleModal();
    const enteredLoanAmount = parseFloat(loanAmount);

    if (enteredLoanAmount >= Number(minLoanAmt)) {
      const principle_amttemp = parseFloat(loan_offersmodel.principle_amount);
      if (enteredLoanAmount <= principle_amttemp) {
        setprincipalAmount(enteredLoanAmount);
        setShouldCallDG(true);
        setShowModal(false);
      } else {
        setErrorMessage("Please enter valid loan amount.");
        return;
      }
    } else {
      setErrorMessage("Please enter loan amount.");
      return;
    }
  };

  const closeTab = () => {
    clearInterval(time_interval);
    newTab_new?.postMessage("Close tab", "*");
    newTab_new?.close();
    navigate("/AddBankDetails");
  };

  const TimeStart = (offers_map_json: OffersMapJson) => {
    time_interval = setInterval(() => {
      calltimerstart(offers_map_json);
    }, 10000);
  };

  const calltimerstart = async (offers_map_json: OffersMapJson) => {
    const requestBodyData = {
      action: "on_status",
      transaction_id: offers_map_json?.context?.transaction_id,
      subscriber_id: offers_map_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [offers_map_json?.context?.bpp_id],
    };

    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };

    offerDetailsLogCheckPostApi(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        console.error("/openfinance/extract-audit-logs", response);
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            //comment
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const status = jsonObject.xinput.form_response.status;
                  if (status === "APPROVED") {
                    const prevTimeStamp = offers_map_json.context.timestamp;
                    const currentTimeStamp = response.data.context.timestamp;
                    const prevDateTime = new Date(prevTimeStamp);
                    const currDateTime = new Date(currentTimeStamp);
                    if (currDateTime > prevDateTime && status === "APPROVED") {
                      if (!newTab_new?.closed) {
                        clearInterval(time_interval);
                        closeTab();
                      } else {
                        clearInterval(time_interval);
                        navigate("/AddBankDetails");
                      }
                    }
                  } else {
                    toast.error(toastErrorMessages.offerDetailsErrorMessage);
                    clearInterval(time_interval);
                    setTimeout(() => {
                      return navigate(
                        `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
                      );
                    }, 3000);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  // call post api for DG locker web view
  const getDGLockerWebView = async () => {
    if (!gotNewOfferData) {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        txn_id: tx_id,
        contact_number: ctn_num || applyPhoneNumber,
        Authorization: `Bearer ${token}`,
      };
      const pay_data: any = {
        selected_offer: offer_jsonobejct,
        loan_amount_adjusted: loan_offersmodel.principle_amount,
      };
      selectOfferPostApi(
        API_ENDPOINTS.select_offer,
        pay_data,
        headers,
        (response) => {
          dispatch(setOffersMapData(JSON.stringify(response.data)));
          if (response != null) {
            if (response.data != null) {
              const keyToDescriptorCode = "descriptor";
              const list = response.data.message.order.items;
              if (list && list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                  const jsonObject = list[i];
                  if (jsonObject && jsonObject[keyToDescriptorCode]) {
                    const url = jsonObject.xinput.form.url;
                    if (url && url.length > 0) {
                      TimeStart(response.data);
                      newTab_new = window.open(url, "_blank");
                    }
                  }
                }
              }
            }
          }
        },
        (error) => {
          toast.error(
            toastErrorMessages.offerDetailsNoOfferErrorMessage || error,
          );
          setTimeout(() => {
            return navigate(
              `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
            );
          }, 3000);
        },
      );
    } else {
      setIsLoading(true);
      const fulljsondata = JSON.parse(bankOfferDataUpdated);
      const url = JSON.parse(kyc_url);
      TimeStart(fulljsondata);
      newTab_new = window.open(url, "_blank");
    }
  };

  const getDGLockerWebView_two = async () => {
    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };

    const pay_data = {
      selected_offer: offer_jsonobejct,
      loan_amount_adjusted: principalAmount,
    };
    selectOfferPostApi(
      API_ENDPOINTS.select_offer,
      pay_data,
      headers,
      (response) => {
        dispatch(setBankOfferData(JSON.stringify(response.data)));
        dispatch(setOffersMapData(JSON.stringify(response.data)));
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const url = jsonObject.xinput.form.url;
                  if (url && url.length > 0) {
                    dispatch(setKycUrlData(JSON.stringify(url)));
                    setGotNewOfferData(true);
                    setNewOfferData(response.data);
                    // TimeStart(response.data);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        toast.error(
          toastErrorMessages.offerDetailsNoOfferErrorMessage || error,
        );
        setTimeout(() => {
          return navigate(
            `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
          );
        }, 3000);
      },
    );
  };

  const setNewOfferData = (offers_map_json: {
    context: Context;
    message: { order: Order };
  }) => {
    const fulljsondata = offers_map_json;
    console.log("offers_map_json", offers_map_json);
    const single_json = fulljsondata;
    setoffersobject(single_json);
    const message = single_json?.message;
    const order = message.order;
    const new_offermodel = { ...loan_offersmodel };
    if (order) {
      const provider = order.provider;
      if (provider) {
        const descriptor = provider.descriptor;
        if (descriptor) {
          new_offermodel.bankName = descriptor.name
            ? descriptor.name.toString()
            : "";
          const bank_name = new_offermodel.bankName;
          dispatch(setBankNameData(bank_name));
          const images = descriptor.images;
          if (images && images.length > 0) {
            for (const image of images) {
              const imageUrl = image.url ? image.url : "";
              new_offermodel.imageUrl = imageUrl;
              break;
            }
          }
        }

        const tagsList = provider.tags;
        if (tagsList && tagsList.length > 0) {
          for (const tagData of tagsList) {
            const listTemp = tagData.list;
            if (listTemp && listTemp.length > 0) {
              for (const jsonObject of listTemp) {
                if (jsonObject.descriptor) {
                  const name = jsonObject.descriptor.name
                    ? jsonObject.descriptor.name.toString()
                    : "";
                  const res = jsonObject.descriptor.code
                    ? jsonObject.descriptor.code.toString()
                    : "";
                  if (name === "Gro name") {
                    const groName = name;
                    new_offermodel.groName = groName;
                  }

                  if (res === "GRO_NAME") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.groNameValue = groName;
                  }

                  if (name === "Gro email") {
                    const groEmail = name;
                    new_offermodel.groEmail = groEmail;
                  }

                  if (res === "GRO_EMAIL") {
                    const groemail =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.groEmailValue = groemail;
                  }

                  if (name === "Gro contact number") {
                    const groName = name;
                    new_offermodel.groContactNumber = groName;
                  }

                  if (res === "GRO_CONTACT_NUMBER") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.groContactNumberValue = groName;
                  }

                  if (name === "Gro Designation") {
                    const groName = name;
                    new_offermodel.groDesignation = groName;
                  }

                  if (res === "GRO_DESIGNATION") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.groDesignationValue = groName;
                  }

                  if (name === "Gro Address") {
                    const groName = name;
                    new_offermodel.groAddress = groName;
                  }

                  if (res === "GRO_ADDRESS") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.groAddressValue = groName;
                  }

                  if (name === "Customer support link") {
                    const groName = name;
                    new_offermodel.customerSupportLink = groName;
                  }

                  if (res === "CUSTOMER_SUPPORT_LINK") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.customerSupportLinkValue = groName;
                  }

                  if (name === "Customer support contact") {
                    const groName = name;
                    new_offermodel.customerSupportContactNumber = groName;
                  }

                  if (res === "CUSTOMER_SUPPORT_CONTACT_NUMBER") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.customerSupportContactNumberValue = groName;
                  }

                  if (name === "Customer support email") {
                    const groName = name;
                    new_offermodel.customerSupportEmail = groName;
                  }

                  if (res === "CUSTOMER_SUPPORT_EMAIL") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.customerSupportEmailValue = groName;
                  }

                  if (name === "Lsp name") {
                    const groName = name;
                    new_offermodel.lspName = groName;
                  }

                  if (res === "LSP_NAME") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.lspNameValue = groName;
                  }

                  if (name === "Lsp email") {
                    const groName = name;
                    new_offermodel.lspEmail = groName;
                  }

                  if (res === "LSP_EMAIL") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.lspEmailValue = groName;
                  }

                  if (name === "Lsp contact number") {
                    const groName = name;
                    new_offermodel.lspContactNumber = groName;
                  }

                  if (res === "LSP_CONTACT_NUMBER") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.lspContactNumberValue = groName;
                  }

                  if (name === "Lsp Address") {
                    const groName = name;
                    new_offermodel.lspAddress = groName;
                  }

                  if (res === "LSP_ADDRESS") {
                    const groName =
                      jsonObject["value"] != null
                        ? jsonObject["value"].toString()
                        : "";
                    new_offermodel.lspAddressValue = groName;
                  }
                }
              }
            }
          }
        }
      }

      const itemsList = order.items;
      if (itemsList && itemsList.length > 0) {
        for (const itemsObj of itemsList) {
          if (itemsObj.price) {
            const loanAmount = itemsObj.price.value;
            new_offermodel.loanAmount = loanAmount;
            const tags = itemsObj.tags;
            if (tags && tags.length > 0) {
              for (const tag of tags) {
                const dataList = tag.list;
                if (dataList && dataList.length > 0) {
                  for (const listItem of dataList) {
                    if (listItem.descriptor) {
                      const res = listItem.descriptor.code
                        ? listItem.descriptor.code.toString()
                        : "";
                      if (res === "INTEREST_RATE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.interestRateShortDec = short_desc;
                        new_offermodel.interestRate = listItem["value"];
                      }

                      if (res === "TERM") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.loanTermsShortDec = short_desc;
                        new_offermodel.loanTerms = listItem["value"];
                      }

                      if (res === "INTEREST_RATE_TYPE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.interestRateType = short_desc;
                        new_offermodel.interestRateTypeValue =
                          listItem["value"];
                      }

                      if (res === "APPLICATION_FEE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.applicationFees = short_desc;
                        new_offermodel.applicationFeesValue = listItem["value"];
                      }

                      if (res === "FORECLOSURE_FEE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.foreclosurePenalty = short_desc;
                        new_offermodel.foreclosurePenaltyValue =
                          listItem["value"];
                      }

                      if (res === "INTEREST_RATE_CONVERSION_CHARGE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.interestRateConversionCharges =
                          short_desc;
                        if (listItem["value"] === "Not Applicable") {
                          new_offermodel.interestRateConversionChargesValue =
                            "0".toString();
                        } else {
                          new_offermodel.interestRateConversionChargesValue =
                            listItem["value"];
                        }
                      }

                      if (res === "DELAY_PENALTY_FEE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.delayedPaymentsPenalty = short_desc;
                        new_offermodel.delayedPaymentsPenaltyValue =
                          listItem["value"];
                      }

                      if (res === "OTHER_PENALTY_FEE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.otherPenalCharges = short_desc;
                        new_offermodel.otherPenalChargesValue =
                          listItem["value"];
                      }

                      if (res === "TNC_LINK") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.termsConditions = short_desc;
                        new_offermodel.termsConditionsValue = listItem["value"];
                      }

                      if (res === "ANNUAL_PERCENTAGE_RATE") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.annualPercentageRate = short_desc;
                        new_offermodel.annualPercentageRateValue =
                          listItem["value"];
                      }

                      if (res === "REPAYMENT_FREQUENCY") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.repaymentFrequency = short_desc;
                        new_offermodel.repaymentFrequencyValue =
                          listItem["value"];
                      }

                      if (res === "NUMBER_OF_INSTALLMENTS_OF_REPAYMENT") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.numberOfInstallmentsOfRepayment =
                          short_desc;
                        new_offermodel.numberOfInstallmentsOfRepaymentValue =
                          listItem["value"];
                      }

                      if (res === "COOL_OFF_PERIOD") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.coolOffPeriod = short_desc;
                        new_offermodel.coolOffPeriodValue = listItem["value"];
                      }

                      if (res === "INSTALLMENT_AMOUNT") {
                        const short_desc =
                          listItem["descriptor"]["short_desc"] != null
                            ? listItem["descriptor"]["short_desc"].toString()
                            : "";
                        new_offermodel.installmentAmount = short_desc;
                        new_offermodel.installmentAmountValue =
                          listItem["value"];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      const quote = order.quote;
      if (quote) {
        const breakupArray = quote.breakup;
        if (breakupArray && breakupArray.length > 0) {
          for (const breakObject of breakupArray) {
            if (breakObject.title && breakObject.title === "PRINCIPAL") {
              const principle = breakObject.price.value;
              new_offermodel.principle_amount = principle;
            }

            if (breakObject.title && breakObject.title === "PROCESSING_FEE") {
              const pro_fee = breakObject.price.value;
              const pro_fee_title = breakObject.title;
              new_offermodel.processing_fee = pro_fee;
              new_offermodel.processing_fee_title = pro_fee_title;
            }

            if (breakObject.title && breakObject.title === "INTEREST") {
              const interest = breakObject.price.value;
              const int_title = breakObject.title;
              new_offermodel.interest = interest;
              new_offermodel.interest_title = int_title;
            }

            if (
              breakObject.title &&
              breakObject.title === "OTHER_UPFRONT_CHARGES"
            ) {
              const otherUpfrontCharges = breakObject.price.value;
              const otherUpfrontCharges_title = breakObject.title;
              new_offermodel.other_upfront_charges = otherUpfrontCharges;
              new_offermodel.other_upfront_charges_title =
                otherUpfrontCharges_title;
            }

            if (
              breakObject.title &&
              breakObject.title === "INSURANCE_CHARGES"
            ) {
              const insuranceCharges = breakObject.price.value;
              const insurance_title = breakObject.title;
              new_offermodel.insurance_charges = insuranceCharges;
              new_offermodel.insurance_charges_title = insurance_title;
            }

            if (
              breakObject.title &&
              breakObject.title === "NET_DISBURSED_AMOUNT"
            ) {
              const netDisbursedAmt = breakObject.price.value;
              const netDisbursedAmt_title = breakObject.title;
              new_offermodel.net_disbursed_amt = netDisbursedAmt;
              new_offermodel.net_disbursed_amt_title = netDisbursedAmt_title;
            }

            if (breakObject.title && breakObject.title === "OTHER_CHARGES") {
              const otherCharges = breakObject.price.value;
              const otherCharges_title = breakObject.title;
              new_offermodel.other_charges = otherCharges;
              new_offermodel.other_charges_title = otherCharges_title;
              break;
            }
          }
        }
      }
    }

    setLoanOffersModel(new_offermodel);
  };

  console.log(
    "hhhhh",
    bankOfferData,
    bankOfferDataUpdated,
    index,
    gotNewOfferData,
  );

  useEffect(() => {
    if (!gotNewOfferData) {
      const fulljsondata = JSON.parse(bankOfferData);
      const single_json = fulljsondata[index];
      const bpp_id = single_json.context?.bpp_id;
      dispatch(setBppData(bpp_id));
      setoffersobject(single_json);
      const message = single_json?.message;

      const order = message.order;
      const new_offermodel = { ...loan_offersmodel };
      if (order) {
        const provider = order.provider;
        if (provider) {
          const descriptor = provider.descriptor;
          if (descriptor) {
            new_offermodel.bankName = descriptor.name
              ? descriptor.name.toString()
              : "";
            const bank_name = new_offermodel.bankName;
            dispatch(setBankNameData(bank_name));
            const images = descriptor.images;
            if (images && images.length > 0) {
              for (const image of images) {
                const imageUrl = image.url ? image.url : "";
                new_offermodel.imageUrl = imageUrl;
                break;
              }
            }
          }

          const tagsList = provider.tags;
          if (tagsList && tagsList.length > 0) {
            for (const tagData of tagsList) {
              const listTemp = tagData.list;
              if (listTemp && listTemp.length > 0) {
                for (const jsonObject of listTemp) {
                  if (jsonObject.descriptor) {
                    const name = jsonObject.descriptor.name
                      ? jsonObject.descriptor.name.toString()
                      : "";
                    const res = jsonObject.descriptor.code
                      ? jsonObject.descriptor.code.toString()
                      : "";
                    if (name === "Gro name") {
                      const groName = name;
                      new_offermodel.groName = groName;
                    }

                    if (res === "GRO_NAME") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.groNameValue = groName;
                    }

                    if (name === "Gro email") {
                      const groEmail = name;
                      new_offermodel.groEmail = groEmail;
                    }

                    if (res === "GRO_EMAIL") {
                      const groemail =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.groEmailValue = groemail;
                    }

                    if (name === "Gro contact number") {
                      const groName = name;
                      new_offermodel.groContactNumber = groName;
                    }

                    if (res === "GRO_CONTACT_NUMBER") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.groContactNumberValue = groName;
                    }

                    if (name === "Gro Designation") {
                      const groName = name;
                      new_offermodel.groDesignation = groName;
                    }

                    if (res === "GRO_DESIGNATION") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.groDesignationValue = groName;
                    }

                    if (name === "Gro Address") {
                      const groName = name;
                      new_offermodel.groAddress = groName;
                    }

                    if (res === "GRO_ADDRESS") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.groAddressValue = groName;
                    }

                    if (name === "Customer support link") {
                      const groName = name;
                      new_offermodel.customerSupportLink = groName;
                    }

                    if (res === "CUSTOMER_SUPPORT_LINK") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.customerSupportLinkValue = groName;
                    }

                    if (name === "Customer support contact") {
                      const groName = name;
                      new_offermodel.customerSupportContactNumber = groName;
                    }

                    if (res === "CUSTOMER_SUPPORT_CONTACT_NUMBER") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.customerSupportContactNumberValue =
                        groName;
                    }

                    if (name === "Customer support email") {
                      const groName = name;
                      new_offermodel.customerSupportEmail = groName;
                    }

                    if (res === "CUSTOMER_SUPPORT_EMAIL") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.customerSupportEmailValue = groName;
                    }

                    //lsp
                    if (name === "Lsp name") {
                      const groName = name;
                      new_offermodel.lspName = groName;
                    }

                    if (res === "LSP_NAME") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.lspNameValue = groName;
                    }

                    if (name === "Lsp email") {
                      const groName = name;
                      new_offermodel.lspEmail = groName;
                    }

                    if (res === "LSP_EMAIL") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.lspEmailValue = groName;
                    }

                    if (name === "Lsp contact number") {
                      const groName = name;
                      new_offermodel.lspContactNumber = groName;
                    }

                    if (res === "LSP_CONTACT_NUMBER") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.lspContactNumberValue = groName;
                    }

                    if (name === "Lsp Address") {
                      const groName = name;
                      new_offermodel.lspAddress = groName;
                    }

                    if (res === "LSP_ADDRESS") {
                      const groName =
                        jsonObject["value"] != null
                          ? jsonObject["value"].toString()
                          : "";
                      new_offermodel.lspAddressValue = groName;
                    }
                  }
                }
              }
            }
          }
        }

        const itemsList = order.items;
        if (itemsList && itemsList.length > 0) {
          for (const itemsObj of itemsList) {
            if (itemsObj.price) {
              const loanAmount = itemsObj.price.value;
              new_offermodel.loanAmount = loanAmount;
              const tags = itemsObj.tags;
              if (tags && tags.length > 0) {
                for (const tag of tags) {
                  const dataList = tag.list;
                  if (dataList && dataList.length > 0) {
                    for (const listItem of dataList) {
                      if (listItem.descriptor) {
                        const res = listItem.descriptor.code
                          ? listItem.descriptor.code.toString()
                          : "";
                        if (res === "INTEREST_RATE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.interestRateShortDec = short_desc;
                          new_offermodel.interestRate = listItem["value"];
                        }

                        if (res === "TERM") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.loanTermsShortDec = short_desc;
                          new_offermodel.loanTerms = listItem["value"];
                        }

                        if (res === "INTEREST_RATE_TYPE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.interestRateType = short_desc;
                          new_offermodel.interestRateTypeValue =
                            listItem["value"];
                        }

                        if (res === "APPLICATION_FEE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.applicationFees = short_desc;
                          new_offermodel.applicationFeesValue =
                            listItem["value"];
                        }

                        if (res === "FORECLOSURE_FEE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.foreclosurePenalty = short_desc;
                          new_offermodel.foreclosurePenaltyValue =
                            listItem["value"];
                        }

                        if (res === "INTEREST_RATE_CONVERSION_CHARGE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.interestRateConversionCharges =
                            short_desc;
                          if (listItem["value"] === "Not Applicable") {
                            new_offermodel.interestRateConversionChargesValue =
                              "0".toString();
                          } else {
                            new_offermodel.interestRateConversionChargesValue =
                              listItem["value"];
                          }
                        }

                        if (res === "DELAY_PENALTY_FEE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.delayedPaymentsPenalty = short_desc;
                          new_offermodel.delayedPaymentsPenaltyValue =
                            listItem["value"];
                        }

                        if (res === "OTHER_PENALTY_FEE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.otherPenalCharges = short_desc;
                          new_offermodel.otherPenalChargesValue =
                            listItem["value"];
                        }

                        if (res === "TNC_LINK") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.termsConditions = short_desc;
                          new_offermodel.termsConditionsValue =
                            listItem["value"];
                        }

                        if (res === "ANNUAL_PERCENTAGE_RATE") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.annualPercentageRate = short_desc;
                          new_offermodel.annualPercentageRateValue =
                            listItem["value"];
                        }

                        if (res === "REPAYMENT_FREQUENCY") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.repaymentFrequency = short_desc;
                          new_offermodel.repaymentFrequencyValue =
                            listItem["value"];
                        }

                        if (res === "NUMBER_OF_INSTALLMENTS_OF_REPAYMENT") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.numberOfInstallmentsOfRepayment =
                            short_desc;
                          new_offermodel.numberOfInstallmentsOfRepaymentValue =
                            listItem["value"];
                        }

                        if (res === "COOL_OFF_PERIOD") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.coolOffPeriod = short_desc;
                          new_offermodel.coolOffPeriodValue = listItem["value"];
                        }

                        if (res === "INSTALLMENT_AMOUNT") {
                          const short_desc =
                            listItem["descriptor"]["short_desc"] != null
                              ? listItem["descriptor"]["short_desc"].toString()
                              : "";
                          new_offermodel.installmentAmount = short_desc;
                          new_offermodel.installmentAmountValue =
                            listItem["value"];
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        const quote = order.quote;
        if (quote) {
          const breakupArray = quote.breakup;
          if (breakupArray && breakupArray.length > 0) {
            for (const breakObject of breakupArray) {
              if (breakObject.title && breakObject.title === "PRINCIPAL") {
                const principle = breakObject.price.value;
                new_offermodel.principle_amount = principle;
              }

              if (breakObject.title && breakObject.title === "PROCESSING_FEE") {
                const pro_fee = breakObject.price.value;
                const pro_fee_title = breakObject.title;
                new_offermodel.processing_fee = pro_fee;
                new_offermodel.processing_fee_title = pro_fee_title;
              }

              if (breakObject.title && breakObject.title === "INTEREST") {
                const interest = breakObject.price.value;
                const int_title = breakObject.title;
                new_offermodel.interest = interest;
                new_offermodel.interest_title = int_title;
              }

              if (
                breakObject.title &&
                breakObject.title === "OTHER_UPFRONT_CHARGES"
              ) {
                const otherUpfrontCharges = breakObject.price.value;
                const otherUpfrontCharges_title = breakObject.title;
                new_offermodel.other_upfront_charges = otherUpfrontCharges;
                new_offermodel.other_upfront_charges_title =
                  otherUpfrontCharges_title;
              }

              if (
                breakObject.title &&
                breakObject.title === "INSURANCE_CHARGES"
              ) {
                const insuranceCharges = breakObject.price.value;
                const insurance_title = breakObject.title;
                new_offermodel.insurance_charges = insuranceCharges;
                new_offermodel.insurance_charges_title = insurance_title;
              }

              if (
                breakObject.title &&
                breakObject.title === "NET_DISBURSED_AMOUNT"
              ) {
                const netDisbursedAmt = breakObject.price.value;
                const netDisbursedAmt_title = breakObject.title;
                new_offermodel.net_disbursed_amt = netDisbursedAmt;
                new_offermodel.net_disbursed_amt_title = netDisbursedAmt_title;
              }

              if (breakObject.title && breakObject.title === "OTHER_CHARGES") {
                const otherCharges = breakObject.price.value;
                const otherCharges_title = breakObject.title;
                new_offermodel.other_charges = otherCharges;
                new_offermodel.other_charges_title = otherCharges_title;
                break;
              }
            }
          }
        }
      }

      setLoanOffersModel(new_offermodel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const getMinLoanAmount = async () => {
    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };
    const data = {
      transaction_id: tx_id,
      bpp_id: bpp_id,
    };
    offerDetailsPostApi(
      API_ENDPOINTS.min_loan_amount,
      data,
      headers,
      (response) => {
        if (response != null) {
          if (response.data != null) {
            dispatch(
              setMinLoanAmount(JSON.stringify(response.data.min_loan_amount)),
            );
            setMinLoanAmt(response.data.min_loan_amount);
          }
        }
      },
      (error) => {
        toast.error(error);
      },
    );
  };

  useEffect(() => {
    if (bpp_id) getMinLoanAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bpp_id]);

  return (
    <div
      style={
        showModal ? { backgroundColor: "#817d7d" } : { backgroundColor: "" }
      }
    >
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ cursor: "pointer" }}>
            <IoIosArrowBack
              color="white"
              size={25}
              onClick={() => navigate(-1)}
            />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>
              Offers Details
            </span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div>
          <Loader KYCMessage={KYCMessage} />
        </div>
      ) : (
        <>
          <div
            style={{ padding: "10px", display: "flex", alignItems: "center" }}
          >
            <div style={{ paddingLeft: "15px" }}>
              <img
                src={loan_offersmodel?.imageUrl}
                alt="Bank Icon"
                style={{ height: "30px", width: "100px" }}
              />
            </div>
            <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
              <p className="loan_details_header">
                {loan_offersmodel?.bankName}
              </p>
            </div>
          </div>

          <div
            style={{
              margin: "10px 0",
              padding: "10px",
              backgroundColor: "#E0E0E0",
              textAlign: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Loan
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#707070",
                    fontWeight: "bold",
                  }}
                >
                  ₹ {loan_offersmodel?.principle_amount}
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Interest Rate
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#707070",
                    fontWeight: "bold",
                  }}
                >
                  {loan_offersmodel?.interestRate}
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Duration
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#707070",
                    fontWeight: "bold",
                  }}
                >
                  {loan_offersmodel?.loanTerms}
                </div>
              </div>
            </div>
          </div>

          <div
            style={
              showModal
                ? { marginBottom: 0 }
                : { marginBottom: "120px", padding: "20px" }
            }
          >
            <div className="loan_details">
              <h2
                style={{
                  fontSize: "17px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                LOAN DETAILS
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Interest</div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.interest}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.interest_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Interest Rate
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.interestRate}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.interestRateShortDec}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Loan Term
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.loanTerms}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.loanTermsShortDec}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Interest Rate Type
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.interestRateTypeValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.interestRateType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Application Fees
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.applicationFeesValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.applicationFees}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Processing Fees
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.processing_fee}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.processing_fee_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Foreclosure Penalty
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.foreclosurePenaltyValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.foreclosurePenalty}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Other Upfront Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.other_upfront_charges}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.other_upfront_charges_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Insurance Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.insurance_charges}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.insurance_charges_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Other Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.other_charges}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.other_charges_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Interest Rate Conversion Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.interestRateConversionChargesValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Delayed Payment Penalty
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.delayedPaymentsPenaltyValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.delayedPaymentsPenalty}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Other Rental Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.otherPenalChargesValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.otherPenalCharges}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Terms And Conditions
                </div>
              </div>
              <div style={{ maxWidth: "80%" }}>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {loan_offersmodel?.termsConditionsValue}
                    </div>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {" "}
                      {loan_offersmodel?.termsConditions}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Annual Percentage Rate
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.annualPercentageRateValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.annualPercentageRate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Repayment Frequency
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.repaymentFrequencyValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.repaymentFrequency}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Number Of Installments Of Repayment
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.numberOfInstallmentsOfRepaymentValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.numberOfInstallmentsOfRepayment}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Cool Of Period
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.coolOffPeriodValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.coolOffPeriod}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Net Disbursed Amount
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.net_disbursed_amt}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.net_disbursed_amt_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Installment Amount
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {loan_offersmodel?.installmentAmountValue}
                  </div>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.installmentAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="loan_details">
              <h2
                style={{
                  fontSize: "17px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                GRO DETAILS
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Gro Name</div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.groNameValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Gro Email
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.groEmailValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Gro Contact Number
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.groContactNumberValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Gro Designation
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.groDesignationValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Gro Address
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.groAddressValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Customer Support Link
                </div>
              </div>
              <div style={{ maxWidth: "80%" }}>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: "normal",
                        overflow: "hidden",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {loan_offersmodel?.customerSupportLinkValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Customer Support Contact Number
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.customerSupportContactNumberValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Customer Support Contact Email
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.customerSupportEmailValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="loan_details">
              <h2
                style={{
                  fontSize: "17px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                CONTACT DETAILS
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Lsp Name</div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.lspNameValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Lsp Email
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.lspEmailValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Lsp Contact Number
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.lspContactNumberValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingLeft: "10px" }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Lsp Address
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {loan_offersmodel?.lspAddressValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div className="bottom-btn">
            <div>
              <button
                onClick={getDGLockerWebView}
                style={{
                  width: "100%",
                  backgroundColor: "#3946aa",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                {gotNewOfferData === true
                  ? "Proceed"
                  : "Proceed with Eligible Amount"}
              </button>
            </div>
            {!gotNewOfferData && (
              <div className="mt-3">
                <button
                  onClick={toggleModal}
                  style={{
                    width: "100%",
                    backgroundColor: "#3946aa",
                    color: "white",
                    border: "none",
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                >
                  Request Lower Amount
                </button>
              </div>
            )}
          </div>
          {showModal && (
            <div className="" ref={modalRef}>
              <div
                style={{
                  background: "#fff",
                  zIndex: "99999999",
                  position: "relative",
                  paddingBottom: "100%",
                  padding: "20px 20px 20% 20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              >
                <span
                  className="d-flex justify-content-end"
                  onClick={() => setShowModal(false)}
                >
                  <IoIosArrowDown size={25} />
                </span>
                <h2>Loan Amount</h2>
                <div>
                  <input
                    type="number"
                    value={loanAmount}
                    // onChange={(e) => setLoanAmount(e.target.value)}
                    onChange={(e) => setLoanAmount(e.target.value)}
                    placeholder="Enter amount"
                    style={{
                      height: "50px",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  />
                </div>
                {errorMessage && (
                  <div style={{ color: "red", fontSize: "10px" }}>
                    {errorMessage}
                  </div>
                )}

                <div className="bottom-btn">
                  <button
                    onClick={handleLoanRequest}
                    style={{
                      marginLeft: "-10px",
                      width: "100%",
                      backgroundColor: "#3946aa",
                      color: "white",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OffersDetailsShow;
