import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { JSX } from "react/jsx-runtime";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import * as Yup from "yup";
import moment, { Moment } from "moment";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  LoanApplicationValues,
  LoanRequestPayload,
} from "components/IndividualInformation/types";
import {
  API_ENDPOINTS,
  WELCOME_VIEW,
  API_URL,
  toastErrorMessages,
  STAGING_URL,
  STAGING_WELCOME_VIEW,
} from "Constant";
import { setAuthData } from "store/slices/authSlice";
import { RootState } from "store/types/store";
import Loader from "components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { IndividualInformationPostApi } from "api/IndividualInformation";

const Root = styled("div")(() => ({
  backgroundColor: "white",
  height: "100vh",
}));

const Content = styled("div")(() => ({}));

const TextFieldStyled = styled(TextField)(() => ({
  margin: "10px 0",
}));

const SelectFieldStyled = styled(Select)(() => ({
  margin: "10px 0",
}));

const DateFieldStyled = styled(DatePicker)(() => ({
  margin: "10px 0",
  borderRadius: "5px",
  width: "100%",
}));

const alphaRegex = /^[a-zA-Z0-9\-/,@# ]+$/;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "First name must contain only letters")
    .required("First name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last name must contain only letters")
    .required("Last Name is required"),
  dob: Yup.string()
    .required("Date of Birth is required")
    .test("valid-age", "Not eligible", function (value) {
      if (!value) return false;
      const birthDate = moment(value, "DD-MM-YYYY");
      const currentAge = moment().diff(birthDate, "years");
      return currentAge > 18;
    }),
  email: Yup.string().email().required("Email is required"),
  officialemail: Yup.string().email(),
  pan: Yup.string()
    .transform((value) => value.toUpperCase())
    .min(10, "PAN must be exactly 10 characters")
    .max(10, "PAN must be exactly 10 characters")
    .required("PAN is required")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format"),
  contactNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must contain 10 digits")
    .required("Phone number is required"),
  employmentType: Yup.string().required("Employee type is required"),
  income: Yup.number()
    .integer()
    .min(700000, "Minimum income of 7 lakh is required")
    .required("Income is required"),
  companyName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Company Name should only contain A-Z characters")
    .required("Company name is required"),
  udyamNumber: Yup.string(),
  addressL1: Yup.string()
    .matches(
      alphaRegex,
      "Address Line 1 should only contain A-Z, 0-9, and special characters (-, /, , , @, #) and spaces",
    )
    .max(90, "Address Line 1 cannot exceed 90 characters")
    .required("Address Line1 is required"),
  addressL2: Yup.string()
    .max(90, "Address Line 2 cannot exceed 90 characters")
    .matches(
      alphaRegex,
      "Address Line 2 should only contain A-Z, 0-9, and special characters (-, /, , , @, #) and spaces",
    ),
  city: Yup.string()
    .max(50, "City cannot exceed 50 characters")
    .matches(
      alphaRegex,
      "City should only contain A-Z, 0-9, and special characters (-, /, , , @, #) and spaces",
    )
    .required("City is required"),
  state: Yup.string()
    .max(50, "State cannot exceed 50 characters")
    .matches(
      alphaRegex,
      "State should only contain A-Z, 0-9, and special characters (-, /, , , @, #) and spaces",
    )
    .required("State name is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Must be exactly 6 digits")
    .required("Pincode is required"),
  bureauConsent: Yup.boolean().oneOf([true], "Consent is required"),
  gender: Yup.string().required("Gender is required"),
  endUse: Yup.string().required("EndUse is required"),
});

const IndividualInformation = () => {
  const dispatch = useDispatch();
  const { agentID, clientID, apiKey, applyPhoneNumber, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const navigate = useNavigate();
  const [personalDetailsVisible, setPersonalDetailsVisible] = useState(true);
  const [addressDetailsVisible, setAddressDetailsVisible] = useState(true);
  const [employeeDetailsVisible, setEmployeeDetailsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [eligibilityMessage, setEligibilityMessage] = useState("");
  const storedContactNumber = applyPhoneNumber || "";
  const initialValues = {
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    officialemail: "",
    pan: "",
    contactNumber: storedContactNumber,
    employmentType: "",
    income: "",
    companyName: "",
    udyamNumber: "",
    addressL1: "",
    addressL2: "",
    city: "",
    state: "",
    pincode: "",
    aa_code: "aa_01",
    bureauConsent: false,
    agent_id: agentID,
    client_id: clientID,
    gender: "",
    endUse: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        pan: values.pan.toUpperCase(),
        contactNumber: values.contactNumber.replace(/^\+91/, ""),
      };
      fetchLoanTypesData(formattedValues);
    },
  });

  const handleAlphaInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (/^[A-Za-z]*$/.test(value)) {
      formik.setFieldValue(name, value);
    }
  };

  const handleNumericValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (/^[0-9]*$/.test(value)) {
      formik.setFieldValue(name, value);
    }
  };

  const fetchLoanTypesData = async (values: LoanApplicationValues) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const userinfo = { ...values };

      const obj: LoanRequestPayload = {
        txId: "",
        loanType: "",
        consentRedirectUrl: "",
        applicantInfo: initialValues,
      };
      obj["txId"] = uuidv4();
      obj["loanType"] = "PERSONAL_LOAN";
      obj["consentRedirectUrl"] = window.location.href.includes(
        "localhost:3000",
      )
        ? WELCOME_VIEW
        : window.location.href.includes(STAGING_URL)
          ? STAGING_WELCOME_VIEW
          : window.location.href.includes("paynearby-preprod")
            ? `${API_URL}/WelcomeView`
            : `${API_URL}/WelcomeView`;
      obj["applicantInfo"] = userinfo;
      const data = JSON.stringify(obj);
      const headers = {
        "Content-Type": "application/json",
        txn_id: obj.txId,
        contact_number: userinfo.contactNumber,
        Authorization: `Bearer ${token}`,
      };

      IndividualInformationPostApi(
        API_ENDPOINTS.infosave_search_wrapper,
        data,
        headers,
        (response: { data: { body: { url: string } } }) => {
          setIsLoading(false);
          dispatch(
            setAuthData({
              data: data,
              tx_id: headers.txn_id,
              ctn_num: headers.contact_number,
              url: response.data.body.url,
            }),
          );
          navigate("/SelectBank");
        },
        (error) => {
          toast.error(
            toastErrorMessages.offersNotAvailableErrorMessage || error,
          );
          setTimeout(() => {
            return navigate(
              `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
            );
          }, 3000);
        },
      );
    } catch (error: any) {
      toast.error(toastErrorMessages.offersNotAvailableErrorMessage || error);
      setIsLoading(false);
      setTimeout(() => {
        return navigate(
          `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
        );
      }, 3000);
    }
  };

  const handleDateChange = (date: Moment | null) => {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    formik.setFieldValue("dob", formattedDate);
    const currentAge = moment().diff(moment(date), "years");
    if (currentAge <= 18) {
      setEligibilityMessage("Not eligible");
    } else {
      setEligibilityMessage("");
    }
  };

  return (
    <Root>
      <Content>
        <div>
          <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <IoIosArrowBack color="white" size={25} />
              </div>
              <div style={{ flexGrow: 1, textAlign: "center" }}>
                <span style={{ color: "white", fontSize: "17px" }}>
                  Individual Information
                </span>
              </div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="fas fa-info-circle"
                  style={{ color: "white" }}
                ></i>
              </div>
            </div>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <div style={{ padding: "20px" }}>
              {/* Personal details */}
              <div style={{}}>
                <span
                  style={{
                    fontFamily: "SatoshiBold",
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Personal Details
                </span>
                <IconButton
                  onClick={() =>
                    setPersonalDetailsVisible(!personalDetailsVisible)
                  }
                >
                  <SvgIcon fontSize="small">
                    {personalDetailsVisible ? (
                      <path d="M7 10l5 5 5-5z" />
                    ) : (
                      <path d="M7 10l5-5 5 5z" />
                    )}
                  </SvgIcon>
                </IconButton>
              </div>

              <div>
                {personalDetailsVisible && (
                  <div>
                    <form autoComplete="off">
                      <TextFieldStyled
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        value={formik.values.firstName}
                        onChange={handleAlphaInputChange}
                        name="firstName"
                        // required
                        id="firstName"
                        required
                        autoComplete="new-password"
                      />
                      <small className="text-danger">
                        {formik.errors.firstName}
                      </small>
                      <TextFieldStyled
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        value={formik.values.lastName}
                        onChange={handleAlphaInputChange}
                        name="lastName"
                        id="lastName"
                        required
                        autoComplete="new-password"
                      />
                      <small className="text-danger">
                        {formik.errors.lastName}
                      </small>
                      <TextFieldStyled
                        label="Email"
                        type="email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        id="email"
                        required
                        autoComplete="new-password"
                      />
                      <small className="text-danger">
                        {formik.errors.email}
                      </small>
                      <TextFieldStyled
                        label="Official Email Id (Optional)"
                        type="email"
                        onChange={formik.handleChange}
                        name="officialemail"
                        variant="outlined"
                        fullWidth
                        id="officialemail"
                        autoComplete="new-password"
                      />
                      <small className="text-danger">
                        {formik.errors.officialemail}
                      </small>
                      <TextFieldStyled
                        label="PAN number"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        name="pan"
                        value={formik.values.pan?.toUpperCase()}
                        id="pan"
                        required
                        autoComplete="new-password"
                      />
                      <small className="text-danger">{formik.errors.pan}</small>
                      <TextFieldStyled
                        label="Contact Number"
                        variant="outlined"
                        fullWidth
                        value={formik.values.contactNumber}
                        onChange={handleNumericValue}
                        name="contactNumber"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                        error={
                          formik.touched.contactNumber &&
                          Boolean(formik.errors.contactNumber)
                        }
                        helperText={
                          formik.touched.contactNumber &&
                          formik.errors.contactNumber
                        }
                      />
                      <small className="text-danger">
                        {formik.errors.contactNumber}
                      </small>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateFieldStyled
                          label="Please Select DOB*"
                          // style={{ width: "100%", height: "40px" }}
                          onChange={handleDateChange}
                          name="dob"
                          format="DD-MM-YYYY"
                          value={
                            formik.values.dob
                              ? moment(formik.values.dob, "DD-MM-YYYY")
                              : null
                          }
                          // renderInput={(
                          //   params: JSX.IntrinsicAttributes & {
                          //     variant?: TextFieldVariants | undefined;
                          //   } & Omit<
                          //       | FilledTextFieldProps
                          //       | OutlinedTextFieldProps
                          //       | StandardTextFieldProps,
                          //       "variant"
                          //     >
                          // ) => (
                          //   <TextField
                          //     {...params}
                          //     error={Boolean(
                          //       formik.touched.dob && formik.errors.dob
                          //     )}
                          //     helperText={
                          //       formik.touched.dob && formik.errors.dob
                          //         ? formik.errors.dob
                          //         : ""
                          //     }
                          //     onBlur={formik.handleBlur}
                          //   />
                          // )}
                        />
                        {!eligibilityMessage ? (
                          <small className="text-danger">
                            {formik.errors.dob}
                          </small>
                        ) : (
                          ""
                        )}
                      </LocalizationProvider>

                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label2"
                          style={{ marginTop: "10px" }}
                        >
                          Gender*
                        </InputLabel>
                        <SelectFieldStyled
                          labelId="demo-simple-select-label2"
                          label="Gender*"
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          name="gender"
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="transgender">Transgender</MenuItem>
                        </SelectFieldStyled>
                        <small className="text-danger">
                          {formik.errors.gender}
                        </small>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label3"
                          style={{ marginTop: "10px" }}
                        >
                          EndUse*
                        </InputLabel>
                        <SelectFieldStyled
                          labelId="demo-simple-select-label3"
                          label="EndUse*"
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          name="endUse"
                        >
                          <MenuItem value="consumerDurablePurchase">
                            Purchase of Consumer Durables
                          </MenuItem>
                          <MenuItem value="education">Education</MenuItem>
                          <MenuItem value="travel">Travel</MenuItem>
                          <MenuItem value="health">Health</MenuItem>
                          <MenuItem value="other">
                            Other Consumption Purposes
                          </MenuItem>
                        </SelectFieldStyled>
                        <small className="text-danger">
                          {formik.errors.endUse}
                        </small>
                      </FormControl>
                    </form>
                  </div>
                )}
              </div>
              <hr />

              <div>
                <div style={{}}>
                  <span
                    style={{
                      fontFamily: "SatoshiBold",
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    Address Details
                  </span>
                  <IconButton
                    onClick={() =>
                      setAddressDetailsVisible(!addressDetailsVisible)
                    }
                  >
                    <SvgIcon fontSize="small">
                      {addressDetailsVisible ? (
                        <path d="M7 10l5 5 5-5z" />
                      ) : (
                        <path d="M7 10l5-5 5 5z" />
                      )}
                    </SvgIcon>
                  </IconButton>
                </div>
                {addressDetailsVisible && (
                  <div>
                    <input type="text" style={{ display: "none" }} />
                    <input type="password" style={{ display: "none" }} />
                    <TextFieldStyled
                      label="Address Line 1"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      name="addressL1"
                      id="addressL1"
                      autoComplete="new-password"
                      required
                    />

                    <small className="text-danger">
                      {formik.errors.addressL1}
                    </small>
                    <TextFieldStyled
                      label="Address Line 2"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      name="addressL2"
                      id="addressL2"
                      autoComplete="new-password"
                    />
                    <small className="text-danger">
                      {formik.errors.addressL2}
                    </small>
                    <TextFieldStyled
                      label="City"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      name="city"
                      id="city"
                      autoComplete="new-password"
                    />
                    <small className="text-danger">{formik.errors.city}</small>
                    <TextFieldStyled
                      label="State"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      name="state"
                      id="state"
                      autoComplete="new-password"
                    />
                    <small className="text-danger">{formik.errors.state}</small>
                    <TextFieldStyled
                      label="Pincode"
                      type="number"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      name="pincode"
                      id="pincode"
                      autoComplete="new-password"
                    />
                    <small className="text-danger">
                      {formik.errors.pincode}
                    </small>
                  </div>
                )}
              </div>
              <hr />
              <div>
                <div style={{}}>
                  <span
                    style={{
                      fontFamily: "SatoshiBold",
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    Employement Details
                  </span>
                  <IconButton
                    onClick={() =>
                      setEmployeeDetailsVisible(!employeeDetailsVisible)
                    }
                  >
                    <SvgIcon fontSize="small">
                      {employeeDetailsVisible ? (
                        <path d="M7 10l5 5 5-5z" />
                      ) : (
                        <path d="M7 10l5-5 5 5z" />
                      )}
                    </SvgIcon>
                  </IconButton>
                </div>
                {employeeDetailsVisible && (
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label1">
                        Employment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        label="Employment Type"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        name="employmentType"
                        style={{ marginBottom: "10px" }}
                      >
                        <MenuItem value="salaried">Salaried</MenuItem>
                        <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                      </Select>
                      <small className="text-danger">
                        {formik.errors.employmentType}
                      </small>
                    </FormControl>
                    <TextFieldStyled
                      label="Annual Income"
                      variant="outlined"
                      fullWidth
                      value={formik.values.income}
                      onChange={handleNumericValue}
                      name="income"
                      id="income"
                      autoComplete="new-password"
                    />
                    <small className="text-danger">
                      {formik.errors.income}
                    </small>
                    <TextFieldStyled
                      label="Company Name"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      name="companyName"
                      id="companyName"
                      autoComplete="new-password"
                    />
                    <small className="text-danger">
                      {formik.errors.companyName}
                    </small>
                  </div>
                )}
              </div>
              <hr />
              <div>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formik.values.bureauConsent}
                          onChange={formik.handleChange}
                          name="bureauConsent"
                        />
                      }
                      label="I hereby undertake and confirm that the information and data furnished by me to the platform is true and correct. The platform has been integrated with lenders who use Credit Information Companies (CIC) to conduct credit checks on body corporates/MSME and Individuals. I acknowledge and confirm that I have consented to the sharing of sensitive data provided by me with such lenders and CICs for the purposes of obtaining the name and account number of banks with whom I have an open Savings /cash credit or overdraft account. In addition, I consent to the data being used to facilitate the creation of a credit offer which may include the account to which the funds may be disbursed as per prevailing RBI norms."
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {formik.errors.bureauConsent}
                  </small>
                </FormControl>
                <button
                  style={{
                    backgroundColor: "#3946aa",
                    color: "white",
                    fontSize: "17px",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  Save & Continue
                </button>
              </div>
            </div>
          )}
        </div>
      </Content>
    </Root>
  );
};

export default IndividualInformation;
