import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toggleItemSelection } from "store/slices/selectBankSlice";
import { RootState } from "store/types/store";
import "App.css";

const SelectBank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state: RootState) => state.bank.items);
  const isSelectedFlag = useSelector(
    (state: RootState) => state.bank.isSelectedFlag
  );

  const handleItemClick = (index: number) => {
    dispatch(toggleItemSelection(index));
  };

  return (
    <div>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>
              Select Bank
            </span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        {items.length > 0 ? (
          items.map((item, index) => (
            <>
              <div className="mx-3 mt-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ width: "50px", height: "40px" }}>
                    <img className="w-100" src={item.logo} alt="Bank Logo" />
                  </div>
                  <span>{item.title}</span>
                  <input
                    type="checkbox"
                    checked={item.isSelected}
                    onChange={() => handleItemClick(index)}
                  />
                </div>
              </div>
            </>
          ))
        ) : (
          <p>No data</p>
        )}
      </div>
      {isSelectedFlag && (
        <div className="bottom-btn">
          <div style={{ margin: "10px" }}>
            <Link to="/AAListView">
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#3946aa",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectBank;
