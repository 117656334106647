/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { SelectOffersPostApi } from "api/SelectOffers";
import { API_ENDPOINTS, toastErrorMessages } from "Constant";
import { toast } from "react-toastify";
import {
  selectTxId,
  selectCtnNum,
  selectToken,
  setBankOfferData,
  selectApplyPhoneNumber,
} from "store/slices/authSlice";
import {
  FetchLoanTypesDataParams,
  LoanOfferItem,
  SelectOffersState,
} from "store/types/slice/SelectOfferSlice";

export const fetchLoanTypesData = createAsyncThunk<
  LoanOfferItem[],
  FetchLoanTypesDataParams,
  { rejectValue: string }
>(
  "selectOffers/fetchLoanTypesData",
  async ({ navigate }, { getState, dispatch, rejectWithValue }) => {
    dispatch(clearOffers());
    const state = getState() as any;
    const tx_id = selectTxId(state);
    const ctn_num = selectCtnNum(state);
    const token = selectToken(state);
    const apiKey = selectToken(state);
    const agentID = selectToken(state);
    const clientID = selectToken(state);
    const applyPhoneNumber = selectApplyPhoneNumber(state);
    try {
      const headers = {
        "Content-Type": "application/json",
        txn_id: tx_id,
        contact_number: ctn_num || applyPhoneNumber,
        Authorization: `Bearer ${token}`,
      };
      const selectLoanType = {
        transactionId: tx_id,
        action: "FETCH_OFFERS",
      };

      const response = await SelectOffersPostApi(
        API_ENDPOINTS.offers,
        selectLoanType,
        headers,
      );
      if (typeof response !== "string" && response.data) {
        dispatch(setBankOfferData(JSON.stringify(response.data)));
        return response.data;
      } else {
        toast.error(toastErrorMessages.offersNotAvailableErrorMessage);
        setTimeout(() => {
          navigate(
            `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
          );
        }, 3000);
        return rejectWithValue("Offers not available");
      }
    } catch (error: any) {
      toast.error(error);
      return rejectWithValue(error.message);
    }
  },
);

const initialState: SelectOffersState = {
  showlistList: [],
  status: "idle",
  error: null,
};

const selectOffersSlice = createSlice({
  name: "selectOffers",
  initialState,
  reducers: {
    clearOffers: (state) => {
      state.showlistList = [];
    },
    setShowlistList: (state, action: PayloadAction<LoanOfferItem[]>) => {
      state.showlistList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanTypesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchLoanTypesData.fulfilled,
        (state, action: PayloadAction<LoanOfferItem[]>) => {
          state.status = "succeeded";
          state.showlistList = getBankList(action.payload);
        },
      )
      .addCase(
        fetchLoanTypesData.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.status = "failed";
          state.error = action.payload as string;
        },
      );
  },
});

export function getBankList(offersList: LoanOfferItem[]): LoanOfferItem[] {
  const showList: LoanOfferItem[] = [];
  if (offersList.length > 0) {
    offersList.forEach((item) => {
      const loanOffersModel = {
        bankImage: "",
        bankname: "",
        bankLoan: "",
        bankInterestRate: "",
        loan_duration: "",
        bankPeriod: "",
      };

      const message = item.message;
      const order = message?.order;
      const provider = order?.provider;
      const descriptor = provider?.descriptor;
      const bankName = descriptor?.name;
      loanOffersModel.bankname = bankName as string;
      const images = descriptor?.images;
      if (images && images.length > 0) {
        const imageUrl = images[0].url;
        loanOffersModel.bankImage = imageUrl;
      }

      const itemsList = order?.items;
      if (itemsList && itemsList.length > 0) {
        const loanAmount = order.quote.breakup[0].price.value;
        loanOffersModel.bankLoan = loanAmount;
        itemsList.forEach((tempItemList) => {
          const tags = tempItemList.tags;
          if (tags && tags.length > 0) {
            tags.forEach((tagData) => {
              const listTemp = tagData.list;
              if (listTemp && listTemp.length > 0) {
                listTemp.forEach((jsonObject) => {
                  const res = jsonObject.descriptor.code;
                  if (res === "TERM") {
                    loanOffersModel.loan_duration = jsonObject.value;
                  } else if (res === "INTEREST_RATE") {
                    loanOffersModel.bankInterestRate = jsonObject.value;
                  } else if (res === "INSTALLMENT_AMOUNT") {
                    loanOffersModel.bankPeriod = jsonObject.value;
                  }
                });
              }
            });
          }
        });
      }

      showList.push(loanOffersModel);
    });
  }

  return showList;
}

export const { clearOffers, setShowlistList } = selectOffersSlice.actions;

export default selectOffersSlice.reducer;
