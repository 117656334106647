/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  confirmDisbusmentPostApi,
  ResponseContext,
  ResponseOrder,
} from "api/ConfirmDisbusment";
import { API_ENDPOINTS } from "Constant";
import { RootState } from "store/types/store";
import Loader from "components/Loader/Loader";
import right_holder from "assets/images/right_holder.png";
import "react-toastify/dist/ReactToastify.css";
import "components/OffersDetailsShow/OffersDetailsShow.css";

interface ConfirmApiResponse {
  context: ResponseContext;
  message: {
    order: ResponseOrder;
  };
}

const ConfirmDisbusment = () => {
  const navigate = useNavigate();
  const {
    clientID,
    apiKey,
    agentID,
    final_new_disbusment_json,
    tx_id,
    ctn_num,
    token,
    applyPhoneNumber,
  } = useSelector((state: RootState) => state.auth);
  const [mapData, setMapData] = useState<ConfirmApiResponse | null>(null);
  const [isloading, setisloading] = useState(false);
  const [, setDownloading] = useState(false);

  const onHandleSubmit = () => {
    navigate(`/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`);
  };

  const downloadPdf = async (downloadAbleLink: string | URL | Request) => {
    try {
      setDownloading(true);
      const response = await fetch(downloadAbleLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "loan_statement.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloading(false);
    } catch (error: any) {
      console.error("Error downloading PDF:", error);
      toast.error(error);
      setDownloading(false);
    }
  };

  const handleSubmitNew = async () => {
    setisloading(true);
    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };

    const pay_data = {
      loan_status: JSON.parse(final_new_disbusment_json),
    };

    confirmDisbusmentPostApi(
      API_ENDPOINTS.confirm,
      pay_data,
      headers,
      (response) => {
        console.error("Success:", response);
        if (response != null) {
          if (response.data != null) {
            setisloading(false);
            setMapData(response.data);
          }
        }
      },
      (error) => {
        setisloading(false);
        console.error("Error:", error);
        toast.error(error);
      },
    );
  };

  useEffect(() => {
    setisloading(true);
    setTimeout(() => {
      handleSubmitNew();
    }, 10000);
  }, []);

  const principalValue = mapData?.message?.order?.quote?.breakup?.find(
    (item) => item.title === "PRINCIPAL",
  )?.price?.value;

  return (
    <div>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100px",
            height: "150px",
            margin: "auto",
          }}
        >
          <img className="w-100" src={right_holder} alt="Right Holder" />
        </div>
      </div>

      {isloading ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              margin: "10px 0",
              padding: "10px",
              backgroundColor: "#E0E0E0",
              textAlign: "center",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: "15px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Your loan amount of ₹{principalValue}
                is ready to disburse
              </div>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <div className="loan_details">
              <h2
                style={{
                  fontSize: "17px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                LOAN DETAILS
              </h2>
            </div>

            {/* Application Id */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Application Id
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {mapData?.message?.order?.quote?.id}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Interest Rate
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {mapData?.message?.order?.items?.map((ele) =>
                        ele?.tags?.map(
                          (val) =>
                            val?.list?.find(
                              (element) =>
                                element?.descriptor?.code === "INTEREST_RATE",
                            )?.value,
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Processing Fee */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Processing Fee
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {
                        mapData?.message?.order?.quote?.breakup?.find(
                          (item) => item?.title === "PROCESSING_FEE",
                        )?.price?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Other Upfront Charges */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Other Upfront Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {
                        mapData?.message?.order?.quote?.breakup?.find(
                          (item) => item?.title === "OTHER_UPFRONT_CHARGES",
                        )?.price?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Insurance Charges */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Insurance Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {
                        mapData?.message?.order?.quote?.breakup?.find(
                          (item) => item?.title === "INSURANCE_CHARGES",
                        )?.price?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Other Charges */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Other Charges
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {
                        mapData?.message?.order?.quote?.breakup?.find(
                          (item) => item?.title === "OTHER_CHARGES",
                        )?.price?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Net Disbursed Amount */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Net Disbursed Amount
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {
                        mapData?.message?.order?.quote?.breakup?.find(
                          (item) => item?.title === "NET_DISBURSED_AMOUNT",
                        )?.price?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Loan Status */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1, paddingTop: 10, paddingLeft: 20 }}>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  Loan Status
                </div>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <div style={{ padding: 8 }}>
                    <div style={{ fontSize: 12, fontWeight: "normal" }}>
                      {
                        mapData?.message?.order?.fulfillments[0]?.state
                          ?.descriptor?.name
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div>
              <button
                onClick={() =>
                  downloadPdf("https://example.com/loan_statement.pdf")
                }
                style={{
                  backgroundColor: "#3946aa",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "0 10px",
                  cursor: "pointer",
                }}
              >
                Loan Detail PDF
              </button>
            </div>
            <hr />
          </div>

          <div style={{ padding: "20px" }}>
            {mapData?.message?.order?.payments
              // eslint-disable-next-line array-callback-return
              .filter((data) => {
                if (Number(data.id) < 6) {
                  return data;
                }
              })
              .map((data, index) => (
                <div key={index}>
                  <div style={{ textAlign: "left", margin: "10px 0 0 10px" }}>
                    <span style={{ fontSize: 20, fontWeight: "bold" }}>
                      {"Installment"}
                    </span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "left",
                        margin: "10px 10px 0 10px",
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        Amount: {data?.params?.amount}
                      </span>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "left",
                        margin: "10px 10px 0 10px",
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        Status: {data?.status}
                      </span>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "left",
                        margin: "10px 10px 0 10px",
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        Start Date:{" "}
                        {moment(data?.time?.range?.start).format("DD-MM-YYYY")}
                      </span>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "left",
                        margin: "10px 10px 0 10px",
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        End Date:{" "}
                        {moment(data?.time?.range?.end).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
          </div>

          <div className="bottom-btn">
            <div>
              <button
                onClick={onHandleSubmit}
                style={{
                  width: "100%",
                  backgroundColor: "#3946aa",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Done
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmDisbusment;
