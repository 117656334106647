import { Secured } from "utils/HelperFunctions/api";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

// Define types for the response structure

// Describes the issue request part of the response
export interface IssueRequest {
  context: {
    domain: string;
    action: string;
    bap_id: string;
    bap_uri: string;
    bpp_id: string;
    bpp_uri: string;
    transaction_id: string;
    message_id: string;
    timestamp: string;
    ttl: string;
    location: {
      country: {
        code: string;
      };
      city: {
        code: string;
      };
    };
    version: string;
  };
  message: {
    issue: {
      id: string;
      category: string;
      sub_category: string;
      issue_type: string;
      status: string;
      created_at: string;
      updated_at: string;
      complainant_info: {
        person: {
          name: string;
        };
        contact: {
          phone: string;
          email: string;
        };
      };
      order_details: {
        id: string;
        state: string;
        provider_id: string;
      };
      description: {
        short_desc: string;
        long_desc: string;
        additional_desc: {
          url: string;
          content_type: string;
        };
        images: string[];
      };
      source: {
        network_participant_id: string;
        type: string;
      };
      expected_response_time: {
        duration: string;
      };
      expected_resolution_time: {
        duration: string;
      };
      issue_actions: {
        complainant_actions: {
          complainant_action: string;
          short_desc: string;
          updated_at: string;
          updated_by: {
            org: {
              name: string;
            };
            contact: {
              phone: string;
              email: string;
            };
            person: {
              name: string;
            };
          };
        }[];
      };
    };
  };
}

// Describes the issue response part of the response
export interface IssueResponse {
  context: {
    domain: string;
    action: string;
    bap_id: string;
    bap_uri: string;
    bpp_id: string;
    bpp_uri: string;
    transaction_id: string;
    message_id: string;
    timestamp: string;
    ttl: string;
    location: {
      country: {
        code: string;
      };
      city: {
        code: string;
      };
    };
    version: string;
  };
  message: {
    issue: {
      id: string;
      created_at: string;
      updated_at: string;
      issue_actions: {
        respondent_actions: {
          respondent_action: string;
          short_desc: string;
          updated_at: string;
          updated_by: {
            org: {
              name: string;
            };
            contact: {
              phone: string;
              email: string;
            };
            person: {
              name: string;
            };
          };
          cascaded_level: string;
        }[];
      };
    };
  };
}

// Describes the on_issue_status_request part of the response
export interface OnIssueStatusRequest {
  context: {
    domain: string;
    action: string;
    bap_id: string;
    bap_uri: string;
    bpp_id: string;
    bpp_uri: string;
    transaction_id: string;
    message_id: string;
    timestamp: string;
    ttl: string;
    location: {
      country: {
        code: string;
      };
      city: {
        code: string;
      };
    };
    version: string;
  };
  message: {
    issue: {
      id: string;
      created_at: string;
      updated_at: string;
      issue_actions: {
        respondent_actions: {
          respondent_action: string;
          short_desc: string;
          updated_at: string;
          updated_by: {
            org: {
              name: string;
            };
            contact: {
              phone: string;
              email: string;
            };
            person: {
              name: string;
            };
          };
          cascaded_level: string;
        }[];
      };
      resolution_provider: {
        respondent_info: {
          type: string;
          organization: {
            org: {
              name: string;
            };
            contact: {
              phone: string;
              email: string;
            };
            person: {
              name: string;
            };
          };
          resolution_support: {
            chat_link: string;
            contact: {
              phone: string;
              email: string;
            };
            gros: {
              person: {
                name: string;
              };
              contact: {
                phone: string;
                email: string;
              };
              gro_type: string;
            }[];
          };
        };
      };
      resolution: {
        short_desc: string;
        long_desc: string;
        action_triggered: string;
      };
    };
  };
}

export interface Category {
  issue_request: IssueRequest;
  issue_response: IssueResponse;
  on_issue_status_request: OnIssueStatusRequest;
}

// Define the type for the overall response
interface TicketHistoryListResponse {
  issues: Category[];
}

export const ticketHistoryList = async (
  url: string,
  headers: {
    txn_id: string;
    contact_number: string;
    Authorization: string;
  },
  success: (response: {
    status: number;
    data: TicketHistoryListResponse;
  }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.get(url, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
