import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import ApplyForLoan from "components/ApplyForLoan/ApplyForLoan";
import CheckLoanStatus from "components/CheckLoanStatus/CheckLoanStatus";
import IndividualInformation from "components/IndividualInformation/IndividualInformation";
import SelectBank from "components/SelectBank/SelectBank";
import SelectLoanType from "components/SelectLoanType/SelectLoanType";
import Welcome from "components/Welcome/Welcome";
import AAListView from "components/AAListView/AAListView";
import WelcomeView from "components/WelcomeView/WelcomeView";
import SelectOffers from "components/SelectOffers/SelectOffers";
import OffersDetailsShow from "components/OffersDetailsShow/OffersDetailsShow";
import AddBankDetails from "components/AddBankDetails/AddBankDetails";
import Emandate from "components/Emandate/Emandate";
import ConfirmDisbusment from "components/ConfirmDisbusment/ConfirmDisbusment";
import LoanStatus from "components/LoanStatus/LoanStatus";
import Tickets from "components/Tickets/Tickets";
import TicketListView from "components/Tickets/TicketListView";
import TicketRaiseView from "components/Tickets/TicketRaiseView";
import TicketHistory from "components/Tickets/TicketHistory";
import { useSearchParams } from "react-router-dom";
import ErrorPage from "components/ErrorPage/Errorpage";
import MyLoan from "components/Myloan/Myloan";
import CurrentOffer from "components/CurrentOffer/CurrentOffer";
import "assets/style.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [searchParams] = useSearchParams();
  const ag = searchParams.get("agentID");
  const cl = searchParams.get("clientID");
  const ak = searchParams.get("apiKey");
  return (
    <>
      <Routes>
        <Route
          path="/"
          index
          element={
            <Suspense>
              <Welcome />
            </Suspense>
          }
        />
        <Route
          path={`/?agentID=${ag}&clientID=${cl}&apiKey=${ak}`}
          element={<Welcome />}
        />
        <Route
          path="/applyforloan"
          element={
            <Suspense>
              <ApplyForLoan />
            </Suspense>
          }
        />
        <Route
          path="/currentOffer"
          element={
            <Suspense>
              <CurrentOffer />
            </Suspense>
          }
        />
        <Route
          path="/SelectLoanType"
          element={
            <Suspense>
              <SelectLoanType />
            </Suspense>
          }
        />
        <Route
          path="/IndividualInformation"
          element={
            <Suspense>
              <IndividualInformation />
            </Suspense>
          }
        />
        <Route
          path="/SelectBank"
          element={
            <Suspense>
              <SelectBank />
            </Suspense>
          }
        />
        <Route
          path="/AAListView"
          element={
            <Suspense>
              <AAListView />
            </Suspense>
          }
        />
        <Route
          path="/WelcomeView"
          element={
            <Suspense>
              <WelcomeView />
            </Suspense>
          }
        />
        <Route
          path="/SelectOffers"
          element={
            <Suspense>
              <SelectOffers />
            </Suspense>
          }
        />
        <Route
          path="/OffersDetailsShow"
          element={
            <Suspense>
              <OffersDetailsShow />
            </Suspense>
          }
        />
        <Route
          path="/AddBankDetails"
          element={
            <Suspense>
              <AddBankDetails />
            </Suspense>
          }
        />
        <Route
          path="/Emandate"
          element={
            <Suspense>
              <Emandate />
            </Suspense>
          }
        />
        <Route
          path="/ConfirmDisbusment"
          element={
            <Suspense>
              <ConfirmDisbusment />
            </Suspense>
          }
        />
        <Route
          path="/CheckLoanStatus"
          element={
            <Suspense>
              <CheckLoanStatus />
            </Suspense>
          }
        />
        <Route
          path="/myloan"
          element={
            <Suspense>
              <MyLoan />
            </Suspense>
          }
        />
        <Route
          path="/LoanStatus"
          element={
            <Suspense>
              <LoanStatus />
            </Suspense>
          }
        />
        <Route
          path="/Tickets"
          element={
            <Suspense>
              <Tickets />
            </Suspense>
          }
        />
        <Route
          path="/TicketRaiseView"
          element={
            <Suspense>
              <TicketRaiseView />
            </Suspense>
          }
        />
        <Route
          path="/TicketListView"
          element={
            <Suspense>
              <TicketListView />
            </Suspense>
          }
        />
        <Route
          path="/Tickets/ticket-history/:id"
          element={
            <Suspense>
              <TicketHistory />
            </Suspense>
          }
        />
        <Route
          path="/error"
          element={
            <Suspense>
              <ErrorPage />
            </Suspense>
          }
        />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
