import { Secured } from "utils/HelperFunctions/api";
import { AxiosResponse } from "axios";
import { errorMessage } from "constant/AppConstant";
import { LoanOfferItem } from "store/types/slice/SelectOfferSlice";

export const SelectOffersPostApi = async (
  url: string,
  data: {
    transactionId: string | null;
    action: string;
  },
  headers: any
): Promise<AxiosResponse<LoanOfferItem[]> | string> => {
  try {
    const response = await Secured.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return errorMessage;
    }
  } catch (err) {
    return errorMessage || err;
  }
};
