/* eslint-disable no-unused-vars */
import { Secured } from "utils/HelperFunctions/api";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

export interface ResponseContext {
  domain: string;
  action: string;
  bap_id: string;
  bap_uri: string;
  bpp_id: string;
  bpp_uri: string;
  transaction_id: string;
  message_id: string;
  timestamp: string;
  ttl: string;
  location: {
    country: {
      code: string;
    };
    city: {
      code: string;
    };
  };
  version: string;
}

export interface ResponseOrder {
  id: string;
  provider: {
    id: string;
    descriptor: {
      name: string;
      short_desc: string;
      long_desc: string;
      images: {
        size_type: string;
        url: string;
      }[];
    };
    tags: {
      descriptor: {
        name: string;
        code: string;
      };
      list: {
        descriptor: {
          name: string;
          code: string;
        };
        value: string;
      }[];
    }[];
  };
  items: ResponseOrderItem[];
  documents: {
    url: string;
    descriptor: {
      name: string;
      code: string;
      short_desc: string;
      long_desc: string;
    };
    mime_type: string;
  }[];
  fulfillments: {
    state: {
      descriptor: {
        name: string;
        code: string;
      };
    };
    customer: {
      person: {
        name: string;
      };
      contact: {
        phone: string;
        email: string;
      };
    };
  }[];
  quote: {
    price: {
      currency: string;
      value: string;
    };
    breakup: {
      title: string;
      price: {
        currency: string;
        value: string;
      };
    }[];
    ttl: string;
    id: string;
  };
  payments: {
    type: string;
    status: string;
    collected_by?: string;
    id: string;
    params?: {
      amount: string;
      currency: string;
    };
    time?: {
      label: string;
      range: {
        start: string;
        end: string;
      };
    };
  }[];
  cancellation_terms: {
    fulfillment_state: {
      descriptor: {
        code: string;
      };
    };
    cancellation_fee: {
      percentage: string;
    };
    external_ref: {
      url: string;
      mimetype: string;
    };
  }[];
}

interface ResponseOrderItem {
  id: string;
  descriptor: {
    name: string;
    code: string;
  };
  price: {
    currency: string;
    value: string;
  };
  tags: {
    descriptor: {
      name: string;
      code: string;
    };
    list: {
      descriptor: {
        name: string;
        code: string;
        short_desc?: string;
      };
      value: string;
    }[];
  }[];
  display: boolean;
}

interface ConfirmApiResponse {
  context: ResponseContext;
  message: {
    order: ResponseOrder;
  };
}
export const confirmDisbusmentPostApi = async (
  url: string,
  data: {
    loan_status: any;
  },
  headers: {
    "Content-Type": string;
    txn_id: string | null;
    contact_number: string | null;
    Authorization: string;
  },
  success: (response: { status: number; data: ConfirmApiResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
