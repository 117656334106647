/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import moment from "moment";
import { myLoanGetApi } from "api/Myloan";
import { API_URL } from "Constant";
import { RootState } from "store/types/store";
import { ApiResponse } from "components/Myloan/types";
import { setLoansData, setLoanIndex } from "store/slices/authSlice";
import "react-toastify/dist/ReactToastify.css";
import "components/SelectOffers/SelectOffers.css";

const MyLoan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, phoneNumber, loansData } = useSelector(
    (state: RootState) => state.auth,
  );
  const [myLoanData, setMyLoanData] = useState<ApiResponse[] | null>(null);

  const handleMyLoan = async () => {
    const headers = {
      contact_number: phoneNumber,
      Authorizarion: `Bearer ${token}`,
    };

    myLoanGetApi(
      `${API_URL}/api/my-loan/dashboard`,
      headers,
      (response) => {
        if (response.data != null) {
          dispatch(setLoansData(JSON.stringify(response.data)));
          setMyLoanData(JSON.parse(loansData));
        } else {
          throw new Error("Something went wrong");
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  useEffect(() => {
    handleMyLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loansData]);

  const handleMyloanDetail = (index: number) => {
    dispatch(setLoanIndex(index));
    navigate("/LoanStatus");
  };
  const myLoanDataLength = myLoanData?.length ?? 0;

  return (
    <>
      <div>
        <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <IoIosArrowBack color="white" size={25} />
            </div>
            <div style={{ flexGrow: 1, textAlign: "center" }}>
              <span style={{ color: "white", fontSize: "17px" }}>My Loan</span>
            </div>
            <div style={{ cursor: "pointer" }}>
              <i className="fas fa-info-circle" style={{ color: "white" }}></i>
            </div>
          </div>
        </div>
        <div style={{ padding: "20px" }}>
          {myLoanDataLength > 0
            ? myLoanData?.map((myloan, index) => (
                <div
                  key={index}
                  className="offer-item"
                  onClick={() => handleMyloanDetail(index)}
                >
                  <div className="offer-info mb-2">
                    <div className="d-flex align-items-center gap-2">
                      <div style={{ width: "25px" }}>
                        <img
                          src={
                            myloan.message?.order.provider.descriptor.images[0]
                              ?.url
                          }
                          className="bank-logo w-100"
                          alt="bank-logo"
                        />
                      </div>
                      <span
                        className="bank-name fw-bold"
                        style={{ fontSize: "13px" }}
                      >
                        {myloan.message?.order.provider.descriptor.name}
                      </span>
                    </div>
                    <span style={{ cursor: "pointer" }}>
                      <IoIosArrowForward color="black" size={25} />
                    </span>
                  </div>
                  <div className="offer-details">
                    <div className="offer-detail">
                      <span className="fw-light" style={{ fontSize: "15px" }}>
                        Loan Amount
                      </span>
                      <span className="fw-bold" style={{ fontSize: "15px" }}>
                        {myloan.message?.order.quote.breakup[0].price.value}
                      </span>
                    </div>
                    <div className="offer-detail">
                      <span className="fw-light" style={{ fontSize: "15px" }}>
                        Interest Rate
                      </span>
                      <span className="fw-bold" style={{ fontSize: "15px" }}>
                        {
                          myloan.message?.order.items[0]?.tags[0]?.list[0]
                            ?.value
                        }
                      </span>
                    </div>
                  </div>

                  <div className="offer-details">
                    <div className="offer-detail">
                      <span className="fw-light" style={{ fontSize: "15px" }}>
                        Tenure
                      </span>
                      <span className="fw-bold" style={{ fontSize: "15px" }}>
                        {
                          myloan.message?.order.items[0]?.tags[0]?.list[1]
                            ?.value
                        }
                      </span>
                    </div>
                    <div className="offer-detail">
                      <span className="fw-light" style={{ fontSize: "15px" }}>
                        EMI Date
                      </span>
                      <span className="fw-bold" style={{ fontSize: "15px" }}>
                        {moment
                          .utc(
                            myloan.message?.order.payments[0]?.time?.range
                              .start,
                          )
                          .format("MM/DD/YY")}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};

export default MyLoan;
