/* eslint-disable no-unused-vars */
import { Secured } from "utils/HelperFunctions/api";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

interface Price {
  currency: string;
  value: string;
}

interface Descriptor {
  name: string;
  code: string;
  short_desc?: string;
  long_desc?: string;
}

interface TagDescriptor {
  name: string;
  code: string;
}

interface Tag {
  descriptor: TagDescriptor;
  list: TagListItem[];
  display?: boolean;
}

interface TagListItem {
  descriptor: TagDescriptor;
  value: string;
}

interface Document {
  url: string;
  descriptor: Descriptor;
  mime_type: string;
}

interface FulfillmentState {
  descriptor: TagDescriptor;
}

interface Person {
  name: string;
}

interface Contact {
  phone: string;
  email: string;
}

interface Fulfillment {
  state: FulfillmentState;
  customer: {
    person: Person;
    contact: Contact;
  };
}

interface QuoteBreakup {
  title: string;
  price: Price;
}

interface PaymentTagDescriptor {
  code: string;
}

interface PaymentTag {
  descriptor: PaymentTagDescriptor;
  display: boolean;
  list: PaymentTagListItem[];
}

interface PaymentTagListItem {
  descriptor: PaymentTagDescriptor;
  value: string;
}

interface PaymentParams {
  amount: string;
  currency: string;
}

interface Payment {
  type: string;
  status: string;
  collected_by?: string;
  id: string;
  params?: PaymentParams;
  time?: {
    label: string;
    range: {
      start: string;
      end: string;
    };
  };
  tags?: PaymentTag[];
}

interface CancellationTerms {
  fulfillment_state: {
    descriptor: TagDescriptor;
  };
  cancellation_fee: {
    percentage: string;
  };
  external_ref: {
    url: string;
    mimetype: string;
  };
}

interface Order {
  id: string;
  provider: {
    id: string;
    descriptor: Descriptor;
    tags: Tag[];
  };
  items: {
    id: string;
    descriptor: Descriptor;
    price: Price;
    tags: Tag[];
  }[];
  documents: Document[];
  fulfillments: Fulfillment[];
  quote: {
    price: Price;
    breakup: QuoteBreakup[];
    ttl: string;
    id: string;
  };
  payments: Payment[];
  cancellation_terms: CancellationTerms[];
}

interface Context {
  domain: string;
  action: string;
  bap_id: string;
  bap_uri: string;
  bpp_id: string;
  bpp_uri: string;
  transaction_id: string;
  message_id: string;
  timestamp: string;
  ttl: string;
  location: {
    country: {
      code: string;
    };
    city: {
      code: string;
    };
  };
  version: string;
}

interface ApiResponse {
  context: Context;
  message: {
    order: Order;
  };
}

export const myLoanGetApi = async (
  url: string,
  headers: {
    contact_number: string;
    Authorizarion: string;
  },
  success: (response: { status: number; data: ApiResponse }) => void,
  error: ErrorsCallback,
) => {
  try {
    const response = await Secured.get(url, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
