import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { setAddBankData } from "store/slices/authSlice";
import { RootState } from "store/types/store";
import {
  Context,
  Order,
  addBankDetailsPostApi,
  addBankDetailsLogCheck,
} from "api/AddBankDetails";
import { API_ENDPOINTS, LOGS_CHECK } from "Constant";
import { ENACHMessage } from "constant/AppConstant";
import Loader from "components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
interface ResponseData {
  context: Context;
  message: {
    order: Order;
  };
}
let newTab_new: Window | null;
let time_interval: string | number | NodeJS.Timeout | undefined;
const validationSchema = Yup.object({
  selectedAccountType: Yup.string().required(),
  accountHolderName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "AccountHolderName must be in characters")
    .required("Account holder name is required"),
  ifscCode: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code")
    .required("IFSC code is required"),
  bankName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "BankName must contain characters")
    .required("Bank name is required"),
  accountNumber: Yup.string() // Use string for easier validation
    .matches(/^\d{9,18}$/, "Must be between 9 and 18 digits")
    .required("Account number is required"),
  reEnterAccountNumber: Yup.string()
    .matches(/^\d{9,18}$/, "Account number must be between 9 and 18 digits")
    .oneOf([Yup.ref("accountNumber")], "Account numbers must match")
    .required("Re-enter Account number is required"),
});

const AddBankDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    clientID,
    apiKey,
    agentID,
    tx_id,
    ctn_num,
    token,
    offers_map_json,
    applyPhoneNumber,
  } = useSelector((state: RootState) => state.auth);

  const initialValues = {
    selectedAccountType: "",
    accountHolderName: "",
    ifscCode: "",
    bankName: "",
    accountNumber: "",
    reEnterAccountNumber: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      setIsLoading(true);
      handleSubmit();
    },
  });

  const TimeStart = (currect_bank_json: ResponseData) => {
    time_interval = setInterval(() => {
      calltimerstart(currect_bank_json);
    }, 10000);
  };

  function validateTimeStamp(
    prevTime: string,
    currTime: string,
    postKycID: string,
    submissionId: string,
    status: string,
    add_bank_json: ResponseData,
  ) {
    postKycID;
    submissionId;
    status;
    add_bank_json;
    const prevDateTime = new Date(prevTime);
    const currDateTime = new Date(currTime);
    if (currDateTime > prevDateTime) {
      clearInterval(time_interval);
      newTab_new?.postMessage("Close tab", "*");
      newTab_new?.close();
      navigate("/Emandate");
    }
  }

  const calltimerstart = async (add_bank_json: ResponseData) => {
    const requestBodyData = {
      action: "on_status",
      transaction_id: add_bank_json?.context?.transaction_id,
      subscriber_id: add_bank_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [add_bank_json?.context?.bpp_id],
    };
    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };
    addBankDetailsLogCheck(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        // eslint-disable-next-line no-console
        console.error("/openfinance/extract-audit-logs", response);
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const status = jsonObject.xinput.form_response.status;
                  if (status === "APPROVED") {
                    const prevTimeStamp = add_bank_json.context.timestamp;
                    const currentTimeStamp = response.data.context.timestamp;
                    const keyToDescriptorCode = "xinput";
                    let status = "";
                    let submission_id = "";
                    const list = response.data.message.order.items;
                    if (list.length > 0) {
                      for (const jsonObject of list) {
                        if (
                          Object.prototype.hasOwnProperty.call(
                            jsonObject,
                            keyToDescriptorCode,
                          )
                        ) {
                          status = jsonObject.xinput.form_response.status;
                          submission_id = jsonObject.xinput.form.id;
                        }
                      }
                    }

                    const postKycID =
                      add_bank_json.message.order.items[0].xinput.form.id;
                    validateTimeStamp(
                      prevTimeStamp,
                      currentTimeStamp,
                      postKycID,
                      submission_id,
                      status,
                      add_bank_json,
                    );
                  } else {
                    toast.error(
                      "Unable to proceed further due to E-NACH failure.",
                    );
                    clearInterval(time_interval);
                    setTimeout(() => {
                      navigate(
                        `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
                      );
                    }, 3000);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        toast.error(error);
      },
    );
  };

  const handleSubmit = async () => {
    const post_kyc_json = offers_map_json;
    const headers = {
      "Content-Type": "application/json",
      txn_id: tx_id,
      contact_number: ctn_num || applyPhoneNumber,
      Authorization: `Bearer ${token}`,
    };

    const pay_data = {
      postKyc: JSON.parse(post_kyc_json),
      acc_holder_name: formik.values.accountHolderName,
      acc_type: formik.values.selectedAccountType,
      acc_no: formik.values.accountNumber,
      ifsc_code: formik.values.ifscCode,
    };

    addBankDetailsPostApi(
      API_ENDPOINTS.disbursement,
      pay_data,
      headers,
      (response) => {
        dispatch(setAddBankData(JSON.stringify(response.data)));
        if (response != null) {
          if (response.data != null) {
            setIsLoading(false);

            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const url = jsonObject.xinput.form.url;
                  if (url && url.length > 0) {
                    TimeStart(response.data);
                    newTab_new = window.open(url, "_blank");
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        setIsLoading(false);
        toast.error(error);
      },
    );
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "#3946aa", padding: "20px", height: "15vh" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>Add Bank</span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div>
          <Loader ENACHMessage={ENACHMessage} />
        </div>
      ) : (
        <div style={{ padding: "20px" }}>
          <div className="fw-bold" style={{ marginBottom: "20px" }}>
            Adding your bank account will help us credit the loan amount
            instantly.
          </div>

          <FormControl component="fieldset">
            <FormLabel className="fw-bold" component="legend">
              Account Type
            </FormLabel>
          </FormControl>
          <div style={{ padding: "20px" }}>
            <RadioGroup
              value={formik.values.selectedAccountType}
              name="selectedAccountType"
              onChange={formik.handleChange}
              className="flex-row mb-3"
            >
              <FormControlLabel
                value="current"
                control={<Radio />}
                label={
                  <span style={{ fontWeight: "bold", fontSize: "10px" }}>
                    Current Account
                  </span>
                }
              />
              <FormControlLabel
                value="saving"
                control={<Radio />}
                label={
                  <span style={{ fontWeight: "bold", fontSize: "10px" }}>
                    Saving Account
                  </span>
                }
              />
            </RadioGroup>
            <TextField
              label="Account Holder Name"
              variant="outlined"
              name="accountHolderName"
              value={formik.values.accountHolderName}
              onChange={formik.handleChange}
              fullWidth
            />
            <small className="text-danger">
              {formik.errors.accountHolderName}
            </small>
            <TextField
              label="IFSC Code"
              name="ifscCode"
              variant="outlined"
              value={formik.values.ifscCode}
              onChange={formik.handleChange}
              fullWidth
              style={{ marginTop: "20px" }}
            />
            <small className="text-danger">{formik.errors.ifscCode}</small>
            <TextField
              label="Bank Name"
              name="bankName"
              variant="outlined"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              fullWidth
              style={{ marginTop: "20px" }}
            />
            <small className="text-danger">{formik.errors.bankName}</small>
            <TextField
              label="Account Number"
              name="accountNumber"
              variant="outlined"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              fullWidth
              style={{ marginTop: "20px" }}
            />
            <small className="text-danger">{formik.errors.accountNumber}</small>
            <TextField
              label="Re-Enter Account Number"
              name="reEnterAccountNumber"
              variant="outlined"
              value={formik.values.reEnterAccountNumber}
              onChange={formik.handleChange}
              fullWidth
              style={{ marginTop: "20px" }}
            />
            <small className="text-danger">
              {formik.errors.reEnterAccountNumber}
            </small>
            <button
              style={{
                backgroundColor: "#3946aa",
                color: "white",
                fontSize: "17px",
                padding: "10px 20px",
                borderRadius: "10px",
                border: "none",
                marginTop: "20px",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => formik.handleSubmit()}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBankDetails;
