import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import authReducer from "store/slices/authSlice";
import loanApplicationReducer from "store/slices/loanApplicationSlice";
import loanTypeReducer from "store/slices/loanTypeSlice";
import bankReducer from "store/slices/selectBankSlice";
import AAReducer from "store/slices/AASlice";
import selectOffersReducer from "store/slices/selectOfferSlice";
import { RootState } from "store/types/store";

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  loanApplication: loanApplicationReducer,
  loanType: loanTypeReducer,
  bank: bankReducer,
  aa: AAReducer,
  selectOffers: selectOffersReducer,
});

type PersistedReducer = ReturnType<typeof persistReducer<RootState>>;

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
) as PersistedReducer;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
