import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setQueryParams,
  loginUser,
  setShowButton,
  resetState,
} from "store/slices/authSlice";
import { APIKEY, PASSWORD, toastErrorMessages } from "Constant";
import Loader from "components/Loader/Loader";
import { RootState } from "store/types/store";
import { AppDispatch } from "store/store";
import logo from "assets/images/protean.png";

const Welcome = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { agentID, clientID, apiKey, showButton, status } = useSelector(
    (state: RootState) => state.auth
  );
  const password = PASSWORD;

  const updateQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const agentID = searchParams.get("agentID") || "";
    const clientID = searchParams.get("clientID") || "";
    const apiKey = searchParams.get("apiKey") || "";

    dispatch(setQueryParams({ agentID, clientID, apiKey }));
  };

  useEffect(() => {
    dispatch(resetState());
    updateQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, dispatch]);

  useEffect(() => {
    if (checkClientIDandAgentID(agentID, clientID)) {
      if (
        apiKey === APIKEY &&
        (clientID === "NBT_Saathi" || clientID === "nbt")
      ) {
        dispatch(loginUser({ clientID, agentID, apiKey, password }));
      } else {
        dispatch(setShowButton(false));
      }
    } else {
      dispatch(setShowButton(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentID, clientID, apiKey, dispatch]);

  const handleApplyLoan = () => {
    if (
      apiKey === APIKEY &&
      (clientID === "NBT_Saathi" || clientID === "nbt")
    ) {
      navigate("/applyforloan");
    } else {
      toast.error(toastErrorMessages.welcomeScreenUnthorizedErrorMessage);
    }
  };

  const handleCheckStatus = () => {
    if (
      apiKey === APIKEY &&
      (clientID === "NBT_Saathi" || clientID === "nbt")
    ) {
      navigate("/CheckLoanStatus");
    } else {
      toast.error(toastErrorMessages.welcomeScreenUnthorizedErrorMessage);
    }
  };

  const checkClientIDandAgentID = (agentID: string, clientID: string) => {
    const regex = /^[0-9]+$/;
    return (
      (clientID === "NBT_Saathi" && agentID === "null") ||
      (clientID === "nbt" &&
        regex.test(agentID) &&
        agentID.length > 0 &&
        agentID.length < 8)
    );
  };

  if (status === "loading" || showButton === undefined) {
    return <Loader />;
  }

  return (
    <div>
      {showButton ? (
        <>
          <div
            className="header"
            style={{ backgroundColor: "#3946aa", padding: "20px" }}
          >
            <img
              src={logo}
              alt="Logo"
              className="logo"
              style={{ height: "50px" }}
            />
          </div>

          <div className="content" style={{ padding: "20px" }}>
            <h2 style={{ color: "#3946aa", fontWeight: "bold" }}>Welcome</h2>
            <div className="content">
              <button
                style={{
                  fontSize: "17px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  width: "100%",
                  border: "none",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                onClick={handleApplyLoan}
              >
                Apply for Loan
              </button>
            </div>
            <div className="content">
              <button
                style={{
                  fontSize: "17px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  width: "100%",
                  border: "none",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                onClick={handleCheckStatus}
              >
                Check Loan Status
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10%",
              padding: "10px",
            }}
          >
            <div
              style={{ color: "grey", fontSize: "50px", fontWeight: "bold" }}
            >
              401
            </div>
            <div
              style={{ color: "#495057", fontSize: "30px", fontWeight: "bold" }}
            >
              WE&apos;RE SORRY, YOUR REQUEST IS UNAUTHORIZED
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Welcome;
