import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import TicketRaiseView from "components/Tickets/TicketRaiseView";
import TicketListView from "components/Tickets/TicketListView";

const Tickets = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>Tickets</span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <button
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            borderBottom:
              activeTab === 0 ? "2px solid #3946aa" : "2px solid transparent",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            cursor: "pointer",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "200px",
          }}
          onClick={() => setActiveTab(0)}
        >
          Report an Issue
        </button>
        <button
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            borderBottom:
              activeTab === 1 ? "2px solid #3946aa" : "2px solid transparent",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            cursor: "pointer",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "200px",
          }}
          onClick={() => setActiveTab(1)}
        >
          Ticket Status
        </button>
      </div>

      <div>
        {activeTab === 0 && <TicketRaiseView />}
        {activeTab === 1 && <TicketListView />}
      </div>
    </>
  );
};

export default Tickets;
