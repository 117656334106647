/* eslint-disable no-unused-vars */
import { Secured } from "utils/HelperFunctions/api";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback, HeaderType } from "interface";

interface SerachWrapperInterfcae {
  header: {
    rid: null;
    ts: null;
    channelId: null;
  };
  body: {
    url: string;
  };
}

type SuccessCallback = (response: {
  status: number;
  data: SerachWrapperInterfcae;
}) => void;

export const IndividualInformationPostApi = async (
  url: string,
  data: string,
  headers: HeaderType,
  success: SuccessCallback,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
