/* eslint-disable no-console */
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import axios from "axios";
import { API_ENDPOINTS, BASE_ENV } from "Constant";
import { RootState } from "store/types/store";
import Loader from "components/Loader/Loader";
import "components/Tickets/Style.css";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { Category, ticketHistoryList } from "api/TicketHistory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Root = styled("div")(() => ({
  backgroundColor: "#ffffff",
}));

// const InfoContainer = styled("div")(({ theme }) => ({
//   backgroundColor: "#ffffff",
//   borderRadius: "10px",
//   boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
//   padding: theme.spacing(2),
//   marginTop: theme.spacing(2),
// }));

const ButtonContainer = styled("div")({
  textAlign: "right",
});

const TicketHistory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { transact_id, phoneNumber, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const [issue, setissue] = useState<Category | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorData, setErrorData] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [feedback, setFeedback] = useState("");

  const handleLikeClick = () => {
    setFeedback("THUMBS-UP");
    handleOpen();
  };

  const handleDislikeClick = () => {
    setFeedback("THUMBS-DOWN");
    handleOpen();
  };

  const handleButton = (id: string | undefined) => {
    closeIssue(id);
  };

  const closeIssue = async (id: string | undefined) => {
    try {
      setIsLoading(true);
      const transactionID = transact_id;
      const phone = phoneNumber;
      const bearerToken = token;
      if (!transactionID || !phone || !bearerToken) {
        setIsLoading(false);
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        txn_id: transactionID,
        contact_number: phone,
        Authorization: `Bearer ${bearerToken}`,
        "ngrok-skip-browser-warning": "69420",
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/${BASE_ENV}/igm/issue/close`,
        {
          issue_id: id,
          rating: feedback,
        },
        { headers: headers },
      );
      response;
      setIsLoading(false);
      navigate(-1);
    } catch (error: any) {
      if (
        error.response.data.error.type === "Adapter" ||
        error.response.data.error.code === "1070"
      ) {
        setErrorData(true);
      }

      setIsLoading(false);
    }
  };

  const IssueList = async () => {
    const transactionID = transact_id;
    const phone = phoneNumber;

    const headers = {
      txn_id: transactionID,
      contact_number: phone,
      Authorization: `Bearer ${token}`,
    };

    ticketHistoryList(
      API_ENDPOINTS.igm_issue_list,
      headers,
      (response) => {
        if (response != null) {
          if (response.data != null) {
            setIsLoading(false);
            if (response.data.issues.length > 0) {
              const element = response.data.issues.find(
                (ticket) => ticket.issue_request.message.issue.id === params.id,
              );
              setissue(element as Category);
            } else {
              setissue(null);
            }
          }
        }
      },
      (error) => {
        console.error("Error:", error);
        toast.error(error);
      },
    );
  };

  useEffect(() => {
    IssueList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>
              Ticket History
            </span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="card"
          style={{
            width: " 90%",
            margin: "10px auto",
            padding: "10px",
          }}
        >
          {!errorData ? (
            <div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Status :{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {issue?.on_issue_status_request?.message.issue?.issue_actions
                    ?.respondent_actions[1]?.respondent_action === "RESOLVED"
                    ? "RESOLVED"
                    : issue?.on_issue_status_request?.message.issue
                        ?.issue_actions?.respondent_actions[0]
                        ?.respondent_action}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Ticket id:{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {issue?.issue_request?.message?.issue?.id}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Category:{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {issue?.issue_request?.message?.issue?.category}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Sub-category:{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {issue?.issue_request?.message?.issue?.sub_category}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  short desc:{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {issue?.on_issue_status_request?.message?.issue?.issue_actions
                    ?.respondent_actions[1]?.short_desc == "Complaint Resolved"
                    ? "Complaint Resolved"
                    : issue?.on_issue_status_request?.message?.issue
                        ?.issue_actions?.respondent_actions[0]?.short_desc}
                </span>
              </div>

              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Created at:{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {moment(
                    issue?.issue_response?.message?.issue?.created_at,
                  ).format("DD-MM-YYYY HH:MM")}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Updated at:{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {moment(
                    issue?.issue_response?.message?.issue?.updated_at,
                  ).format("DD-MM-YYYY HH:MM")}
                </span>
              </div>

              <ButtonContainer>
                {issue?.on_issue_status_request.message?.issue.issue_actions
                  ?.respondent_actions[1]?.respondent_action === "RESOLVED" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <div>
                        <h5>Rate your experience</h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <AiFillLike
                          color="blue"
                          size="30"
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={handleLikeClick}
                        />

                        <AiFillDislike
                          color="red"
                          size="30"
                          style={{ cursor: "pointer" }}
                          onClick={handleDislikeClick}
                        />
                      </div>
                    </div>
                  </>
                )}
              </ButtonContainer>
              <div style={{ margin: "2%" }}>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Do you want to close your ticket?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={() =>
                          handleButton(
                            issue?.issue_response?.message?.issue?.id,
                          )
                        }
                      >
                        Yes
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          ) : (
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              We&apos;re sorry, Not getting the response from the render
            </Typography>
          )}
        </div>
      )}
    </Root>
  );
};

export default TicketHistory;
