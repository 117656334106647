import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getClosedIssue, getIgmIssue, Issue } from "api/TicketListView";
import { API_ENDPOINTS } from "Constant";
import Loader from "components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import "components/OffersDetailsShow/OffersDetailsShow.css";
import { RootState } from "store/types/store";

const TicketListView = () => {
  const navigate = useNavigate();
  const { transact_id, phoneNumber, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const [list, setList] = useState<Issue[] | null>(null);
  const [closeList, setClosedList] = useState<Issue[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const IssueList = async () => {
    setIsLoading(true);
    const transactionID = transact_id;
    const phone = phoneNumber;

    const headers = {
      txn_id: transactionID,
      contact_number: phone,
      Authorization: `Bearer ${token}`,
    };

    getIgmIssue(
      API_ENDPOINTS.igm_issue_list,
      headers,
      (response) => {
        if (response != null) {
          if (response.data != null) {
            setList(response.data.issues || []);
          }

          setIsLoading(false);
        }

        setIsLoading(false);
      },
      (error) => {
        toast.error(error);
        setIsLoading(false);
      },
    );
  };

  const ClosedIssueList = async () => {
    setIsLoading(true);
    const transactionID = transact_id;
    const phone = phoneNumber;

    const headers = {
      txn_id: transactionID,
      contact_number: phone,
      Authorization: `Bearer ${token}`,
    };

    getClosedIssue(
      API_ENDPOINTS.close_issue_list,
      headers,
      (response) => {
        if (response != null) {
          if (response.data != null) {
            setClosedList(response.data.issues || []);
          }

          setIsLoading(false);
        }
      },
      (error) => {
        toast.error(error);
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    activeTab === 0 && IssueList();
    activeTab === 1 && ClosedIssueList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const listLength = list?.length ?? 0;

  const closedListLength = closeList?.length ?? 0;

  return (
    <div>
      <div style={{ display: "flex" }}>
        <button
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            borderBottom:
              activeTab === 0 ? "2px solid #3946aa" : "2px solid transparent",
            backgroundColor: "transparent",
            border: "none",
            outline: "2px",
            cursor: "pointer",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "200px",
          }}
          onClick={() => {
            setActiveTab(0);
            setList([]);
          }}
        >
          Open issue
        </button>

        <button
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            borderBottom:
              activeTab === 1 ? "2px solid #3946aa" : "2px solid transparent",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            cursor: "pointer",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "200px",
          }}
          onClick={() => {
            setActiveTab(1);
            setClosedList([]);
          }}
        >
          Closed issue
        </button>
      </div>
      <div>
        {activeTab === 0 && (
          <div>
            {isLoading ? (
              <Loader />
            ) : listLength ? (
              list?.map((ticket) => (
                <div
                  className="card"
                  style={{
                    width: " 90%",
                    margin: "10px auto",
                    padding: "10px",
                  }}
                  key={ticket.issue_request.message.issue.id}
                  onClick={() => {
                    navigate(
                      `ticket-history/${ticket.issue_request.message.issue.id}`,
                    );
                  }}
                >
                  <div style={{}}>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Status :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket?.on_issue_status_request?.message.issue
                        ?.issue_actions?.respondent_actions[1]
                        ?.respondent_action === "RESOLVED"
                        ? "RESOLVED"
                        : ticket?.on_issue_status_request?.message.issue
                            ?.issue_actions?.respondent_actions[0]
                            ?.respondent_action}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Ticket ID :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket.issue_request.message.issue.id}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Category :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket.issue_request.message.issue.category}
                    </span>
                  </div>
                  <div style={{}}>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Short Desc :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket?.on_issue_status_request?.message.issue
                        ?.issue_actions?.respondent_actions[1]?.short_desc ==
                      "Complaint Resolved"
                        ? "Complaint Resolved"
                        : ticket?.on_issue_status_request?.message.issue
                            ?.issue_actions?.respondent_actions[0]?.short_desc}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Sub-category :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket.issue_request.message.issue.sub_category}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Created at :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {new Date(
                        ticket.issue_response.message.issue.created_at,
                      ).toLocaleString()}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Updated at :
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {new Date(
                        ticket?.on_issue_status_request?.message?.issue?.issue_actions?.respondent_actions[0]?.updated_at,
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p>No Data Found</p>
            )}
          </div>
        )}
        {activeTab === 1 && (
          <div>
            {isLoading ? (
              <Loader />
            ) : closedListLength ? (
              closeList?.map((ticket) => (
                <div
                  className="card"
                  style={{
                    width: " 90%",
                    margin: "10px auto",
                    padding: "10px",
                  }}
                  key={ticket.issue_request.message.issue.id}
                >
                  <div style={{}}>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Status :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket?.on_issue_status_request?.message.issue.status}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Ticket ID s :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket.issue_request.message.issue.id}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Category :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket.issue_request.message.issue.category}
                    </span>
                  </div>
                  <div style={{}}>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Short Desc :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {
                        ticket?.on_issue_status_request?.message.issue
                          ?.issue_actions?.respondent_actions[0]?.short_desc
                      }
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Sub-category :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {ticket.issue_request.message.issue.sub_category}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Created at :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {new Date(
                        ticket.issue_response.message.issue.created_at,
                      ).toLocaleString()}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Updated at :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {new Date(
                        ticket?.on_issue_status_request?.message?.issue?.issue_actions?.respondent_actions[0]?.updated_at,
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p>No Data Found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketListView;
