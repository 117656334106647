import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setPhoneNumber,
  selectLoanApplication,
} from "store/slices/loanApplicationSlice";
import { AppDispatch } from "store/store";
import { API_ENDPOINTS, toastErrorMessages } from "Constant";
import { RootState } from "store/types/store";
import { setApplyPhoneNumber } from "store/slices/authSlice";
import logo from "assets/images/protean.png";
import { currentOfferGetApi } from "api/CurrentOffer";

const ApplyForLoan = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { phoneNumber } = useSelector(selectLoanApplication);
  const { token, agentID, clientID, apiKey } = useSelector(
    (state: RootState) => state.auth,
  );

  const handlePhoneNumberChange = (e: { target: { value: string } }) => {
    dispatch(setPhoneNumber(e.target.value));
  };

  function validatePhoneNumber(phoneNumber: string) {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(phoneNumber);
  }

  const handleVerify = () => {
    if (phoneNumber === "") {
      toast.error(toastErrorMessages.enterPhoneNumberErrorMessage);
      return;
    } else if (!validatePhoneNumber(phoneNumber)) {
      toast.error(toastErrorMessages.enterValidPhoneNumberErrorMessage);
      return;
    }
 
    dispatch(setApplyPhoneNumber(phoneNumber));

    const headers = {
      contact_number: phoneNumber,
      Authorizarion: `Bearer ${token}`,
    };

    currentOfferGetApi(
      API_ENDPOINTS.others_api,
      headers,
      (response) => {
        if (response.data != null) {
          navigate("/currentOffer");
        }
      },(error: any) => {
        if (error.response.status === 401) {
          setTimeout(() => {
            return navigate(`/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`);
          }, 3000);
        } else if (error.response.data.error.code === "2010") {
          navigate("/SelectLoanType");
        } else {
          navigate("/currentOffer");
        }
      },
    );

  };

  return (
    <div>
      <div
        className="header"
        style={{ backgroundColor: "#3946aa", padding: "20px" }}
      >
        <img
          src={logo}
          alt="Logo"
          className="logo"
          style={{ height: "50px" }}
        />
      </div>
      <div className="content" style={{ padding: "20px" }}>
        <h2 style={{ color: "#3946aa", fontWeight: "bold" }}>Welcome</h2>
        <h2 style={{ color: "#3946aa", fontWeight: "bold" }}>
          Let&apos;s get started
        </h2>
        <div
          className="content"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span
            style={{
              fontSize: "17px",
              padding: "10px",
              marginTop: "20px",
              borderRadius: "10px 0 0 10px",
              border: "1px solid #3946aa",
              backgroundColor: "#f0f0f0",
            }}
          >
            +91
          </span>
          <input
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Phone Number"
            style={{
              fontSize: "17px",
              padding: "10px",
              marginTop: "20px",
              borderRadius: "0 10px 10px 0",
              border: "1px solid #3946aa",
              borderLeft: "none",
              width: "100%",
            }}
          />
        </div>
        <button
          onClick={handleVerify}
          style={{
            backgroundColor: "#3946aa",
            color: "white",
            fontSize: "17px",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "none",
            marginTop: "20px",
            cursor: "pointer",
            width: "100%",
          }}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default ApplyForLoan;
