/* eslint-disable no-unused-vars */
import { Secured } from "utils/HelperFunctions/api";
import axios from "axios";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

interface Descriptor {
  name: string;
  code: string;
  short_desc?: string;
  long_desc?: string;
}

interface Image {
  size_type: string;
  url: string;
}

interface Tag {
  descriptor: Descriptor;
  list: { descriptor: Descriptor; value: string }[];
}

interface Price {
  currency: string;
  value: string;
}

interface Item {
  id: string;
  descriptor: Descriptor;
  price: Price;
  tags: {
    descriptor: Descriptor;
    list: { descriptor: Descriptor; value: string }[];
    display: boolean;
  }[];
  xinput: {
    form: Record<string, unknown>;
    form_response: Record<string, unknown>;
  };
}

interface Document {
  url: string;
  descriptor: Descriptor;
  mime_type: string;
}

interface FulfillmentState {
  descriptor: Descriptor;
}

interface Customer {
  person: {
    name: string;
  };
  contact: {
    phone: string;
    email: string;
  };
}

interface Fulfillment {
  state: FulfillmentState;
  customer: Customer;
}

interface QuoteBreakup {
  title: string;
  price: Price;
}

interface PaymentTag {
  descriptor: Descriptor;
  display: boolean;
  list: { descriptor: Descriptor; value: string }[];
}

interface Payment {
  type: string;
  status: string;
  collected_by?: string;
  id: string;
  params?: {
    amount: string;
    currency: string;
  };
  time?: {
    label: string;
    range: {
      start: string;
      end: string;
    };
  };
  tags?: PaymentTag[];
  url?: string;
}

interface CancellationTerm {
  fulfillment_state: {
    descriptor: Descriptor;
  };
  cancellation_fee: {
    percentage: string;
  };
  external_ref: {
    url: string;
    mimetype: string;
  };
}

// Define the main response type
export interface LoanStatusApiResponse {
  context: {
    domain: string;
    action: string;
    bap_id: string;
    bap_uri: string;
    bpp_id: string;
    bpp_uri: string;
    transaction_id: string;
    message_id: string;
    timestamp: string;
    ttl: string;
    location: {
      country: {
        code: string;
      };
      city: {
        code: string;
      };
    };
    version: string;
  };
  message: {
    order: {
      id: string;
      provider: {
        id: string;
        descriptor: Descriptor;
        images: Image[];
        tags: Tag[];
      };
      items: Item[];
      documents: Document[];
      fulfillments: Fulfillment[];
      quote: {
        price: Price;
        breakup: QuoteBreakup[];
        ttl: string;
        id: string;
      };
      payments: Payment[];
      cancellation_terms: CancellationTerm[];
    };
  };
}

export const loanStatusPostApi = async (
  url: string,
  data: {
    on_confirm: any;
  },
  headers: {
    txn_id: string | null;
    Accept: string;
    contact_number: string | null;
    Authorization: string;
  },
  success: (response: { status: number; data: LoanStatusApiResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};

export const loanUpdatePostApi = async (
  url: string,
  data: {
    updateType: string;
    on_status: any;
    payment_params_amount: string | number;
    payment_params_currency: string;
  },
  headers: {
    txn_id: string;
    contact_number: string;
    Authorization: string;
  },
  success: (response: { status: number; data: LoanStatusApiResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};

export const loanUpdateLogCheck = async (
  url: string,
  data: {
    action: string;
    transaction_id: string;
    subscriber_id: string;
    record_Range: string;
    type: string;
    sellers: string[];
  },
  headers: {
    "Content-Type": string;
    txn_id: string | null;
    contact_number: string | null;
    Authorization: string;
  },
  success: (response: { status: number; data: LoanStatusApiResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await axios.post(url, data, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
