/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RootState } from "store/types/store";
import { API_ENDPOINTS, LOGS_CHECK } from "Constant";
import {
  Offers,
  Context,
  Order,
  currentOfferGetApi,
  timerPostApi,
} from "api/CurrentOffer";
import {
  setAddBankData,
  setBankOfferData,
  setBppData,
  setIndexData,
  setLoanIndex,
  setLoansData,
  setNewDisbusmentData,
  setOffersMapData,
  setTransactionId,
} from "store/slices/authSlice";

let newTab_new: Window | null;
let time_interval: string | number | NodeJS.Timeout | undefined;

interface ShowOffers {
  bankImage: string;
  bankname: string;
  bankLoan: string;
  bankInterestRate: string;
  loan_duration: string;
  bankPeriod: string;
}
interface ContextResponse {
  context: Context;
  message: {
    order: Order;
  };
}
const CurrentOffer = () => {
  const { clientID, apiKey, agentID, token, applyPhoneNumber } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [offers, setOffers] = useState<ShowOffers[] | undefined>(undefined);
  const [apiResponse, setApiResponse] = useState<Offers[] | undefined>(
    undefined,
  );
  const [apiContextResponse, setApiContextResponse] = useState<
    ContextResponse | undefined
  >(undefined);
  const [loading, setLoading] = useState(true); // Initially loading
  const [errorMessage, setErrorMessage] = useState(false);

  //kyc pending
  const TimeStart = (offers_map_json: ContextResponse) => {
    time_interval = setInterval(() => {
      calltimerstart(offers_map_json);
    }, 10000);
  };
  //kyc pending
  const closeTab = () => {
    clearInterval(time_interval);
    newTab_new?.postMessage("Close tab", "*");
    newTab_new?.close();
    navigate("/AddBankDetails");
  };
  //kyc pending
  const calltimerstart = async (offers_map_json: ContextResponse) => {
    const requestBodyData = {
      action: "on_status",
      transaction_id: offers_map_json?.context?.transaction_id,
      subscriber_id: offers_map_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [offers_map_json?.context?.bpp_id],
    };

    const headers = {
      "Content-Type": "application/json",
      txn_id: localStorage.getItem("tx_id"),
      contact_number:
        localStorage.getItem("ctn_num") ||
        localStorage.getItem("applyPhoneNumber"),
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    timerPostApi(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        console.error("/openfinance/extract-audit-logs", response);
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;

            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const status = jsonObject.xinput.form_response.status;
                  if (status === "APPROVED") {
                    const prevTimeStamp = offers_map_json.context.timestamp;
                    const currentTimeStamp = response.data.context.timestamp;
                    const prevDateTime = new Date(prevTimeStamp);
                    const currDateTime = new Date(currentTimeStamp);
                    if (currDateTime > prevDateTime && status === "APPROVED") {
                      if (!newTab_new?.closed) {
                        closeTab();
                      } else {
                        clearInterval(time_interval);
                        navigate("/AddBankDetails");
                      }
                    }
                  } else {
                    toast.error("Unable to proceed further due to KYC failure");
                    clearInterval(time_interval);
                    setTimeout(() => {
                      return navigate(
                        `/?agentID=${agentID}&clientID=${localStorage.getItem(
                          "clientID",
                        )}&apiKey=${apiKey}`,
                      );
                    }, 3000);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  //enach pending
  const TimeStartEnach = (final_new_disbusment_json: ContextResponse) => {
    time_interval = setInterval(() => {
      calltimerstartEnach(final_new_disbusment_json);
    }, 5000);
  };

  //enach pending
  const calltimerstartEnach = async (add_bank_json: ContextResponse) => {
    const requestBodyData = {
      action: "on_status",
      transaction_id: add_bank_json?.context?.transaction_id,
      subscriber_id: add_bank_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [add_bank_json?.context?.bpp_id],
    };

    const headers = {
      "Content-Type": "application/json",
      txn_id: localStorage.getItem("tx_id"),
      contact_number:
        localStorage.getItem("ctn_num") ||
        localStorage.getItem("applyPhoneNumber"),
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    timerPostApi(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        console.error("/openfinance/extract-audit-logs", response);
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const status = jsonObject.xinput.form_response.status;
                  if (status == "APPROVED") {
                    const prevTimeStamp = add_bank_json.context.timestamp;
                    const currentTimeStamp = response.data.context.timestamp;
                    const keyToDescriptorCode = "xinput";
                    let status = "";
                    let submission_id = "";
                    const list = response.data.message.order.items;
                    if (list.length > 0) {
                      for (const jsonObject of list) {
                        if (
                          Object.prototype.hasOwnProperty.call(
                            jsonObject,
                            keyToDescriptorCode,
                          )
                        ) {
                          status = jsonObject.xinput.form_response.status;
                          submission_id = jsonObject.xinput.form.id;
                        }
                      }
                    }

                    const postKycID =
                      add_bank_json.message.order.items[0].xinput.form.id;
                    validateTimeStamp(
                      prevTimeStamp,
                      currentTimeStamp,
                      postKycID,
                      submission_id,
                      status,
                      add_bank_json,
                    );
                  } else {
                    toast.error(
                      "Unable to proceed further due to E-NACH failure.",
                    );
                    // window.location.reload(false);
                    clearInterval(time_interval);
                    setTimeout(() => {
                      navigate(
                        `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
                      );
                    }, 3000);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  //enach pending
  function validateTimeStamp(
    prevTime: string,
    currTime: string,
    postKycID: string,
    submissionId: string,
    status: string,
    add_bank_json: ContextResponse,
  ) {
    postKycID;
    submissionId;
    status;
    add_bank_json;

    const prevDateTime = new Date(prevTime);
    const currDateTime = new Date(currTime);
    if (currDateTime > prevDateTime) {
      clearInterval(time_interval);
      newTab_new?.postMessage("Close tab", "*");
      newTab_new?.close();
      navigate("/Emandate");
    }
  }

  //esign pending
  const TimeStartEsign = (final_new_disbusment_json: ContextResponse) => {
    time_interval = setInterval(() => {
      calltimerstartEsign(final_new_disbusment_json);
    }, 5000);
  };

  //esign pending
  const calltimerstartEsign = async (
    final_new_disbusment_json: ContextResponse,
  ) => {
    const requestBodyData = {
      action: "on_status",
      transaction_id: final_new_disbusment_json?.context?.transaction_id,
      subscriber_id: final_new_disbusment_json?.context?.bap_id,
      record_Range: "0-1",
      type: "bap",
      sellers: [final_new_disbusment_json?.context?.bpp_id],
    };

    const headers = {
      "Content-Type": "application/json",
      txn_id: localStorage.getItem("tx_id"),
      contact_number:
        localStorage.getItem("ctn_num") ||
        localStorage.getItem("applyPhoneNumber"),
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    timerPostApi(
      LOGS_CHECK,
      requestBodyData,
      headers,
      (response) => {
        console.error("Success", response);
        if (response != null) {
          if (response.data != null) {
            const keyToDescriptorCode = "descriptor";
            const list = response.data.message.order.items;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const jsonObject = list[i];
                if (jsonObject && jsonObject[keyToDescriptorCode]) {
                  const status = jsonObject.xinput.form_response.status;
                  if (status == "APPROVED") {
                    const prevTimeStamp =
                      final_new_disbusment_json.context.timestamp;
                    const currentTimeStamp = response.data.context.timestamp;
                    const prevDateTime = new Date(prevTimeStamp);
                    const currDateTime = new Date(currentTimeStamp);
                    if (currDateTime > prevDateTime) {
                      if (!newTab_new?.closed) {
                        closeTabEsign();
                      } else {
                        closeTabEsign();
                      }
                    }
                  } else {
                    toast.error(
                      "Unable to proceed further due to E-SIGN failure",
                    );
                    // window.location.reload(false);
                    clearInterval(time_interval);
                    setTimeout(() => {
                      return navigate(
                        `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
                      );
                    }, 3000);
                  }
                }
              }
            }
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  //esign pending
  const closeTabEsign = () => {
    clearInterval(time_interval);
    setTimeout(() => {
      if (window.location.href.includes("localhost")) {
        window.open("http://localhost:3000/ConfirmDisbusment", "_blank");
      } else if (window.location.href.includes("35.190.114.205")) {
        window.open("http://35.190.114.205/ConfirmDisbusment");
      } else {
        window.location.href.includes("paynearby-preprod")
          ? window.open(
              "https://paynearby-preprod.finance.bharatvyapaar.com/ConfirmDisbusment",
            )
          : window.open(
              "https://paynearby.finance.bharatvyapaar.com/ConfirmDisbusment",
            );
      }
    }, 1000);
  };

  const handleOffersDetailsShow = (index: number) => {
    dispatch(
      setIndexData({
        index: index,
      }),
    );
    if (status === "Rejected") {
      navigate("/SelectLoanType");
    }

    if (status === "Offers Fetched") {
      if (apiResponse && apiResponse.length > 0) {
        dispatch(setBankOfferData(JSON.stringify(apiResponse)));
        dispatch(setTransactionId(apiResponse[0].context.transaction_id));
        const single_json = apiResponse[index];
        const bpp_id = single_json.context?.bpp_id;
        dispatch(setBppData(bpp_id));
        navigate("/OffersDetailsShow");
      }
    }

    if (status === "KYC Pending") {
      if (apiContextResponse) {
        dispatch(setTransactionId(apiContextResponse.context.transaction_id));
        dispatch(setBankOfferData(JSON.stringify(apiContextResponse)));
        dispatch(setOffersMapData(JSON.stringify(apiContextResponse)));
        const keyToDescriptorCode = "descriptor";
        const list = apiContextResponse.message.order.items;
        if (list && list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            const jsonObject = list[i];
            if (jsonObject && jsonObject[keyToDescriptorCode]) {
              const url = jsonObject.xinput.form.url;
              if (url && url.length > 0) {
                TimeStart(apiContextResponse);
                newTab_new = window.open(url, "_blank");
              }
            }
          }
        }
      }
    }

    if (status === "KYC Approved") {
      if (apiContextResponse) {
        dispatch(setOffersMapData(JSON.stringify(apiContextResponse)));
        dispatch(setTransactionId(apiContextResponse.context.transaction_id));
        navigate("/AddBankDetails");
      }
    }

    if (status === "ENACH Pending" || status === "Emandate Pending") {
      if (apiContextResponse) {
        dispatch(setAddBankData(JSON.stringify(apiContextResponse)));
        dispatch(setTransactionId(apiContextResponse.context.transaction_id));
        const keyToDescriptorCode = "descriptor";
        const list = apiContextResponse.message.order.items;
        if (list && list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            const jsonObject = list[i];
            if (jsonObject && jsonObject[keyToDescriptorCode]) {
              const url = jsonObject.xinput.form.url;
              if (url && url.length > 0) {
                TimeStartEnach(apiContextResponse);
                newTab_new = window.open(url, "_blank");
              }
            }
          }
        }
      }
    }

    if (status === "ENACH Approved" || status === "Emandate Approved") {
      if (apiContextResponse) {
        dispatch(setAddBankData(JSON.stringify(apiContextResponse)));
        dispatch(setTransactionId(apiContextResponse.context.transaction_id));
        navigate("/Emandate");
      }
    }

    if (status === "Esign Pending") {
      if (apiContextResponse) {
        dispatch(setNewDisbusmentData(JSON.stringify(apiContextResponse)));
        dispatch(setTransactionId(apiContextResponse.context.transaction_id)); //check
        const keyToDescriptorCode = "descriptor";
        const list = apiContextResponse.message.order.items;
        if (list && list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            const jsonObject = list[i];
            if (jsonObject && jsonObject[keyToDescriptorCode]) {
              const url = jsonObject.xinput.form.url;
              if (url && url.length > 0) {
                TimeStartEsign(apiContextResponse);
                newTab_new = window.open(url, "_blank");
              }
            }
          }
        }
      }
    }

    if (status === "Esign Approved") {
      if (apiContextResponse) {
        dispatch(setTransactionId(apiContextResponse.context.transaction_id));
        dispatch(setNewDisbusmentData(JSON.stringify(apiContextResponse)));
        navigate("/ConfirmDisbusment");
      }
    }

    if (status === "Confirmation Pending") {
      if (apiContextResponse) {
        dispatch(setTransactionId(apiContextResponse.context.transaction_id));
        dispatch(setNewDisbusmentData(JSON.stringify(apiContextResponse)));
        navigate("/ConfirmDisbusment");
      }
    }

    if (status === "Confirmation Approved") {
      navigate("/SelectLoanType");
    }
  };

  function getBankList(offersList: Offers[]) {
    const showList: ShowOffers[] = []; // Initialize an array to store processed loan offers

    // Check if the offers list is not empty
    if (offersList.length > 0) {
      // Iterate through each loan detail item in the offers list
      offersList.forEach((item) => {
        // Initialize a loan offers model object
        const loanOffersModel = {
          bankImage: "",
          bankname: "",
          bankLoan: "",
          bankInterestRate: "",
          loan_duration: "",
          bankPeriod: "",
        };

        // Extract information from the loan detail item
        const message = item.message;
        const order = message.order;
        const provider = order.provider;
        const descriptor = provider.descriptor;

        // Get the bank name and assign it to the loan offers model
        const bankName = descriptor.name;
        loanOffersModel.bankname = bankName;

        // Get the bank image URL and assign it to the loan offers model
        const images = descriptor.images;
        if (images && images.length > 0) {
          const imageUrl = images[0].url;
          loanOffersModel.bankImage = imageUrl;
        }

        // Iterate through each item in the order
        const itemsList = order.items;
        if (itemsList && itemsList.length > 0) {
          // Get the loan amount and assign it to the loan offers model
          const loanAmount = order.quote.breakup[0].price.value;
          loanOffersModel.bankLoan = loanAmount;

          // Iterate through each item in the items list
          itemsList.forEach((tempItemList) => {
            const tags = tempItemList.tags;
            if (tags && tags.length > 0) {
              // Iterate through each tag in the tags list
              tags.forEach((tagData) => {
                const listTemp = tagData.list;
                if (listTemp && listTemp.length > 0) {
                  // Iterate through each object in the listTemp array
                  listTemp.forEach((jsonObject) => {
                    const res = jsonObject.descriptor.code;
                    // Assign values to the loan offers model based on the descriptor code
                    if (res === "TERM") {
                      loanOffersModel.loan_duration = jsonObject.value;
                    } else if (res === "INTEREST_RATE") {
                      loanOffersModel.bankInterestRate = jsonObject.value;
                    } else if (res === "INSTALLMENT_AMOUNT") {
                      loanOffersModel.bankPeriod = jsonObject.value;
                    }
                  });
                }
              });
            }
          });
        }

        // Add the processed loan offers model to the showList array
        showList.push(loanOffersModel);
      });
    }

    setOffers(showList);
  }

  useEffect(() => {
    const headers = {
      contact_number: applyPhoneNumber,
      Authorizarion: `Bearer ${token}`,
    };

    currentOfferGetApi(
      API_ENDPOINTS.others_api,
      headers,
      (response) => {
        if (
          response.data.on_select_two &&
          response.data.on_select_two?.length !== 0
        ) {
          getBankList(response.data.on_select_two);
          setApiResponse(response.data.on_select_two);
          setApiContextResponse(response.data.body);
        } else if (
          response.data.status === "Start the process for the beginning"
        ) {
          setOffers([]);
        }

        if (response.data.status === "INITIATED") {
          setStatus("Initiated");
          dispatch(setLoanIndex(0));
          dispatch(setLoansData(JSON.stringify([response.data.body])));
        }

        if (response.data.status === "FETCH OFFERS") {
          setStatus("Fetch Offers");
        }

        if (response.data.status === "SELECT OFFERS") {
          setStatus("Offers Fetched");
        }

        if (response.data.status === "KYC_PENDING") {
          setStatus("KYC Pending");
        }

        if (response.data.status === "KYC_APPROVED") {
          setStatus("KYC Approved");
        }

        if (response.data.status === "KYC_REJECTED") {
          setStatus("Rejected");
        }

        if (response.data.status === "ENACH_PENDING") {
          setStatus("ENACH Pending");
        }

        if (response.data.status === "ENACH_APPROVED") {
          setStatus("ENACH Approved");
        }

        if (response.data.status === "ENACH_REJECTED") {
          setStatus("Rejected");
        }

        if (response.data.status === "E-MANDATE_PENDING") {
          setStatus("Emandate Pending");
        }

        if (response.data.status === "E-MANDATE_APPROVED") {
          setStatus("Emandate Approved");
        }

        if (response.data.status === "E-MANDATE_REJECTED") {
          setStatus("Rejected");
        }

        if (response.data.status === "ESIGN_PENDING") {
          setStatus("Esign Pending");
        }

        if (response.data.status === "ESIGN_APPROVED") {
          setStatus("Esign Approved");
        }

        if (response.data.status === "ESIGN_REJECTED") {
          setStatus("Rejected");
        }

        if (response.data.status === "CONFIRMATION_APPROVED") {
          setStatus("Confirmation Approved");
        }

        if (response.data.status === "CONFIRMATION_PENDING") {
          setStatus("Confirmation Pending");
        }

        if (
          response.data.error &&
          response.data.error.message ===
            "2010|No record found for provided contact number"
        ) {
          setErrorMessage(true);
        }

        setLoading(false);
      },
      (error: any) => {
        console.error("Error:", error);
        setLoading(false);
        if (error.response.status === 401) {
          setTimeout(() => {
            return navigate(
              `/?agentID=${agentID}&clientID=${clientID}&apiKey=${apiKey}`,
            );
          }, 3000);
        } else if (error.response.data.error.code === "2010") {
          setErrorMessage(true);
        }

        toast.error("Something went wrong please reinitiate the journey.");
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="select-offers-view-container">
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>
              {"Current Offers"}
            </span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        {loading ? (
          <div style={{ position: "relative", height: "50vh" }}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#3946aa", "#3946aa", "#3946aa", "#3946aa", "#3946aa"]}
              />
              <p>Please wait...</p>
            </div>
          </div>
        ) : offers?.length === 0 ? (
          <b>
            Loan is in Sanctioned/Disbursed, please check status. For fresh loan
            initiate the journey.
          </b>
        ) : status === "Confirmation Approved" ? (
          <div
            style={{
              fontSize: "15px",
              color: "#000000",

              padding: "20px",
            }}
          >
            <b>
              Loan is in Sanctioned/Disbursed, please check status. For fresh
              loan initiate the journey
            </b>
          </div>
        ) : errorMessage ? (
          <div
            style={{
              fontSize: "15px",
              color: "#000000",

              padding: "20px",
            }}
          >
            <b>
              No record found for provided contact number. Start a fresh
              journey.
            </b>
          </div>
        ) : (
          offers?.map((offer, index) => (
            <div
              key={index}
              className="offer-item"
              onClick={() => handleOffersDetailsShow(index)}
            >
              <div className="offer-info mb-2">
                <div className="d-flex align-items-center gap-2">
                  <div style={{ width: "15px" }}>
                    <img
                      src={offer.bankImage}
                      className="bank-logo w-100"
                      alt="offer imgs"
                    />
                  </div>
                  <span
                    className="bank-name fw-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {offer.bankname}
                  </span>
                </div>
                <span style={{ cursor: "pointer" }}>
                  <IoIosArrowForward color="black" size={25} />
                </span>
              </div>
              <div className="offer-details">
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Loan
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.bankLoan}
                  </span>
                </div>
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Interest
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.bankInterestRate}
                  </span>
                </div>
              </div>
              <div className="offer-details">
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Tenure
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.loan_duration}
                  </span>
                </div>
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Monthly EMI
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.bankPeriod}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
        {status === "Initiated" && (
          <b>
            Existing Loan application is Sanctioned/Disbursed,{" "}
            <a href="/LoanStatus">click here</a> to check status. To apply new
            loan, proceed below.
          </b>
        )}
        {status === "Fetch Offers" && (
          <b>
            Currently there is no application in progress. Please initiate a
            fresh application.
          </b>
        )}
      </div>
      {status && (
        <div
          style={{
            fontSize: "15px",
            color: "#000000",

            padding: "20px",
          }}
        >
          <em>Current Application Status:</em> <b>{status}</b>
        </div>
      )}
      {(status === "Confirmation Approved" ||
        offers?.length === 0 ||
        errorMessage ||
        status === "Initiated" ||
        status === "Fetch Offers") && (
        <div style={{ margin: "10px" }} className="bottom-btn">
          <button
            onClick={() => navigate("/SelectLoanType")}
            style={{
              backgroundColor: "#3946aa",
              color: "white",
              border: "none",
              borderRadius: "10px",
              padding: "10px 20px",
              width: "100%",
            }}
          >
            Proceed with New Application
          </button>
        </div>
      )}
    </div>
  );
};

export default CurrentOffer;
