import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BankState } from "store/types/slice/SelectBankSlice";

const initialState: BankState = {
  items: [
    {
      title: "HDFC Bank",
      logo: "https://images.hindustantimes.com/rf/image_size_640x362/HT/p2/2016/01/31/Pictures/hdfc-bank-logo-oficial-webiste_6789248c-c840-11e5-88c9-96b817b1d3de.jpg",
      isSelected: false,
    },
    {
      title: "Canara Bank",
      logo: "/images/Canara Bank.jpg",
      isSelected: false,
    },
    {
      title: "Bank Of India",
      logo: "/images/BOI.jpg",
      isSelected: false,
    },
    {
      title: "Bank Of Baroda",
      logo: "/images/BOB.png",
      isSelected: false,
    },
    {
      title: "Bank Of Maharashtra",
      logo: "/images/BOM.png",
      isSelected: false,
    },
    {
      title: "Kotak Bank",
      logo: "/images/Kotak.jpg",
      isSelected: false,
    },
    {
      title: "Yes Bank",
      logo: "/images/Yes Bank.png",
      isSelected: false,
    },
    {
      title: "ICICI Bank",
      logo: "/images/ICICI.png",
      isSelected: false,
    },
    {
      title: "Indian Bank",
      logo: "/images/Indian Bank.jpg",
      isSelected: false,
    },
    {
      title: "AU Small Finance Bank Limited",
      logo: "/images/AU Small Finance Bank Limited.png",
      isSelected: false,
    },
    {
      title: "Axis Bank",
      logo: "/images/Axis Bank.jpg",
      isSelected: false,
    },
    {
      title: "Central Bank of India",
      logo: "/images/Central Bank of India.jpg",
      isSelected: false,
    },
    {
      title: "City Union Bank",
      logo: "/images/City Union Bank.jpg",
      isSelected: false,
    },
    {
      title: "Federal Bank",
      logo: "/images/Federal Bank.png",
      isSelected: false,
    },
    {
      title: "Fincare Small Finance Bank",
      logo: "/images/Fincare Small Finance Bank.jpg",
      isSelected: false,
    },
    {
      title: "IDBI Bank",
      logo: "/images/IDBI Bank.png",
      isSelected: false,
    },
    {
      title: "IDFC First Bank",
      logo: "/images/IDFC First Bank.jpg",
      isSelected: false,
    },
    {
      title: "Indian Overseas Bank",
      logo: "/images/Indian Overseas Bank.png",
      isSelected: false,
    },
    {
      title: "IndusInd Bank",
      logo: "/images/IndusInd Bank.png",
      isSelected: false,
    },
    {
      title: "Karnataka Bank",
      logo: "/images/Karnataka Bank.png",
      isSelected: false,
    },
    {
      title: "Karur Vysya Bank (KVB)",
      logo: "/images/Karur Vysya Bank (KVB).jpg",
      isSelected: false,
    },
    {
      title: "Punjab National Bank",
      logo: "/images/Punjab National Bank.png",
      isSelected: false,
    },
    {
      title: "Punjab & Sind Bank",
      logo: "/images/Punjab & Sind Bank.png",
      isSelected: false,
    },
    {
      title: "SBI",
      logo: "/images/SBI.png",
      isSelected: false,
    },
    {
      title: "South Indian Bank",
      logo: "/images/South Indian Bank.png",
      isSelected: false,
    },
    {
      title: "UCO Bank",
      logo: "/images/UCO Bank.png",
      isSelected: false,
    },
    {
      title: "Union Bank of India",
      logo: "/images/Union Bank of India.png",
      isSelected: false,
    },
  ],
  isSelectedFlag: false,
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    toggleItemSelection: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.items = state.items.map((item, i) => {
        if (i === index) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return { ...item, isSelected: false };
        }
      });
      state.isSelectedFlag = state.items.some((item) => item.isSelected);
    },
  },
});

export const { toggleItemSelection } = bankSlice.actions;

export default bankSlice.reducer;
