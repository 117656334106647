import { Secured } from "utils/HelperFunctions/api";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

interface Location {
  country: {
    code: string;
  };
  city: {
    code: string;
  };
}

interface IssueContext {
  domain: string;
  action: string;
  bap_id: string;
  bap_uri: string;
  bpp_id: string;
  bpp_uri: string;
  transaction_id: string;
  message_id: string;
  timestamp: string;
  ttl: string;
  location: Location;
  version: string;
}

interface IssueDescription {
  short_desc: string;
  long_desc: string;
  additional_desc: {
    url: string;
    content_type: string;
  };
  images: string[];
}

interface Person {
  name: string;
}

interface Contact {
  phone: string;
  email: string;
}

interface ComplainantInfo {
  person: Person;
  contact: Contact;
}

interface OrderDetails {
  id: string;
  state: string;
  provider_id: string;
}

interface IssueMessage {
  id: string;
  category: string;
  sub_category: string;
  issue_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  complainant_info: ComplainantInfo;
  order_details: OrderDetails;
  description: IssueDescription;
  source: {
    network_participant_id: string;
    type: string;
  };
  expected_response_time: {
    duration: string;
  };
  expected_resolution_time: {
    duration: string;
  };
  issue_actions: {
    complainant_actions: {
      complainant_action: string;
      short_desc: string;
      updated_at: string;
      updated_by: {
        org: {
          name: string;
        };
        contact: Contact;
        person: Person;
      };
    }[];
  };
}

interface IssueRequest {
  context: IssueContext;
  message: {
    issue: IssueMessage;
  };
}

interface IssueResponse {
  context: IssueContext;
  message: {
    issue: {
      id: string;
      created_at: string;
      updated_at: string;
      issue_actions: {
        respondent_actions: {
          respondent_action: string;
          short_desc: string;
          updated_at: string;
          updated_by: {
            org: {
              name: string;
            };
            contact: Contact;
            person: Person;
          };
          cascaded_level: string;
        }[];
      };
    };
  };
}

interface IssueStatusRequest {
  context: IssueContext;
  message: {
    issue: {
      id: string;
      created_at: string;
      updated_at: string;
      issue_actions: {
        respondent_actions: {
          respondent_action: string;
          short_desc: string;
          updated_at: string;
          updated_by: {
            org: {
              name: string;
            };
            contact: Contact;
            person: Person;
          };
          cascaded_level: string;
        }[];
      };
      status: string;
      resolution_provider?: {
        respondent_info: {
          type: string;
          organization: {
            org: {
              name: string;
            };
            contact: Contact;
            person: Person;
          };
          resolution_support: {
            chat_link: string;
            contact: Contact;
            gros: {
              person: Person;
              contact: Contact;
              gro_type: string;
            }[];
          };
        };
      };
      resolution?: {
        short_desc: string;
        long_desc: string;
        action_triggered: string;
      };
    };
  };
}

export interface Issue {
  issue_request: IssueRequest;
  issue_response: IssueResponse;
  on_issue_status_request: IssueStatusRequest;
}

interface IssueListResponse {
  issues: Issue[];
}

export const getIgmIssue = async (
  url: string,
  headers: {
    txn_id: string;
    contact_number: string;
    Authorization: string;
  },
  success: (response: { status: number; data: IssueListResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.get(url, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};

export const getClosedIssue = async (
  url: string,
  headers: {
    txn_id: string;
    contact_number: string;
    Authorization: string;
  },
  success: (response: { status: number; data: IssueListResponse }) => void,
  error: ErrorsCallback
) => {
  try {
    const response = await Secured.get(url, { headers: headers });
    if (response.status === 201 || response.status === 200) {
      success(response);
    } else {
      error(errorMessage);
    }
  } catch (err) {
    error(errorMessage || err);
  }
};
