/* eslint-disable no-unused-vars */
import { Secured } from "utils/HelperFunctions/api";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";
import { AAItem } from "store/types/slice/AASlice";

type SuccessCallback = (response: { lsp_list: AAItem[] }) => void;

export const AASliceGetApi = (
  url: string,
  success: SuccessCallback,
  error: ErrorsCallback
) => {
  try {
    Secured.get(url).then((resp) => {
      if (resp.status === 201 || resp.status === 200) {
        success(resp.data);
      } else {
        error(errorMessage);
      }
    });
  } catch (err) {
    error(errorMessage || err);
  }
};
