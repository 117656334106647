import "components/ErrorPage/errorpage.css";
const ErrorPage = () => {
  return (
    <>
      <div className="errorContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10%",
            padding: "10px",
          }}
        >
          <div style={{ color: "grey", fontSize: "50px", fontWeight: "bold" }}>
            401
          </div>
          <div
            style={{ color: "#495057", fontSize: "30px", fontWeight: "bold" }}
          >
            WE&apos;RE SORRY , YOUR REQUEST IS UNAUTHORIZED
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
