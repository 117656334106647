/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "Constant";
import { AASliceGetApi } from "api/AAListView";
import { AAState, AAItem } from "store/types/slice/AASlice";

export const fetchAAList = createAsyncThunk<
  AAItem[],
  void,
  { rejectValue: unknown }
>("aa/fetchAAList", async (_, { rejectWithValue }) => {
  try {
    const response = await new Promise<{ lsp_list: AAItem[] }>(
      (resolve, reject) => {
        AASliceGetApi(API_ENDPOINTS.get_aa_types, resolve, reject);
      },
    );
    return response.lsp_list;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState: AAState = {
  aaListPrint: [],
  isLoading: false,
  selectedItem: "",
};

const AASlice = createSlice({
  name: "aa",
  initialState,
  reducers: {
    toggleItemSelection: (state, action: PayloadAction<string>) => {
      state.selectedItem = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAAList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAAList.fulfilled,
        (state, action: PayloadAction<AAItem[]>) => {
          state.aaListPrint = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(fetchAAList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { toggleItemSelection, setLoading } = AASlice.actions;
export default AASlice.reducer;
