import { Secured } from "utils/HelperFunctions/api";

export const getLoanType = async (url: string) => {
  try {
    const response = await Secured.get(url);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message || "Failed to fetch");
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};
