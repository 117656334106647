import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastErrorMessages } from "Constant";
import { AppDispatch } from "store/store";
import { setPhoneNumberData } from "store/slices/authSlice";
import logo from "assets/images/protean.png";

const CheckLoanStatus = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPhoneNumber(e.target.value);
  };

  function validatePhoneNumber(phoneNumber: string) {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(phoneNumber);
  }

  const handleVerify = () => {
    if (phoneNumber === "") {
      toast.error(toastErrorMessages.enterPhoneNumberErrorMessage);
      return;
    } else if (!validatePhoneNumber(phoneNumber)) {
      toast.error(toastErrorMessages.enterValidPhoneNumberErrorMessage);
      return;
    }

    dispatch(setPhoneNumberData(phoneNumber));
    navigate("/myloan");
  };

  return (
    <div>
      <div
        className="header"
        style={{ backgroundColor: "#3946aa", padding: "20px" }}
      >
        <img
          src={logo}
          alt="Logo"
          className="logo"
          style={{ height: "50px" }}
        />
      </div>
      <div
        className="content"
        style={{ padding: "20px", display: "flex", alignItems: "center" }}
      >
        <span
          style={{
            fontSize: "17px",
            padding: "10px",
            marginTop: "20px",
            borderRadius: "10px 0 0 10px",
            border: "1px solid #3946aa",
            backgroundColor: "#f0f0f0",
          }}
        >
          +91
        </span>
        <input
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Phone Number"
          style={{
            fontSize: "17px",
            padding: "10px",
            marginTop: "20px",
            borderRadius: "0 10px 10px 0",
            border: "1px solid #3946aa",
            borderLeft: "none",
            width: "100%",
          }}
        />
      </div>
      <div style={{ margin: "10px" }} className="bottom-btn">
        <button
          onClick={handleVerify}
          style={{
            backgroundColor: "#3946aa",
            color: "white",
            border: "none",
            borderRadius: "10px",
            padding: "10px 20px",
            cursor: "pointer",
            width: "100%",
          }}
        >
          Get Loan Summary
        </button>
      </div>
    </div>
  );
};

export default CheckLoanStatus;
