export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_ENV = process.env.REACT_APP_BACKEND_ENV;
export const APIKEY = process.env.REACT_APP_API_KEY;
export const PASSWORD = process.env.REACT_APP_PASSWORD;
export const REACT_APP_REALM = process.env.REACT_APP_REALM;
export const LOGS_CHECK = `${process.env.REACT_APP_API_BASE_URL}/${BASE_ENV}/extract-audit-logs`;
export const LOGS_CHECK_UPDATE = `${process.env.REACT_APP_API_BASE_URL}/${BASE_ENV}/extract-audit-logs`;
export const WELCOME_VIEW = `${process.env.REACT_APP_LOCAL_PORT}/WelcomeView`;
export const STAGING_URL = "35.190.114.205";
export const STAGING_WELCOME_VIEW = `${process.env.REACT_APP_STAGING_URL}/WelcomeView`;
export const STAGING_CONFIRM_DISBUSMENT = `${process.env.REACT_APP_STAGING_URL}/ConfirmDisbusment`;
export const CONFIRM_DISBUSMENT = `${process.env.REACT_APP_LOCAL_PORT}/ConfirmDisbusment`;
export const API_ENDPOINTS = {
  get_loan_type: `/${BASE_ENV}/static/loan-types`,
  get_aa_types: `/${BASE_ENV}/static/aa-types`,
  infosave_search_wrapper: `/${BASE_ENV}/search_wrapper-v1`,
  offers: `/${BASE_ENV}/offers`,
  select_offer: `/${BASE_ENV}/select-offer`,
  disbursement: `/${BASE_ENV}/post-kyc/disbursement`,
  finalagreement: `/${BASE_ENV}/post-enach/final-agreement`,
  confirm: `/${BASE_ENV}/confirm`,
  laon_summary: `/${BASE_ENV}/my-loan/summary`,
  laon_status: `/${BASE_ENV}/my-loan/status`,
  laon_update: `/${BASE_ENV}/my-loan/update`,
  upload_igm_issue: `/${BASE_ENV}/igm/issue`,
  igm_issue_list: `/${BASE_ENV}/igm/issue`,
  igm_issue_status: `${BASE_ENV}/igm/issue/status`,
  close_issue: `${BASE_ENV}/igm/issue/close`,
  close_issue_list: `${BASE_ENV}/igm/issue/CLOSED`,
  min_loan_amount: `${BASE_ENV}/min_loan_amt`,
  check_existing_offers: `/${BASE_ENV}/drop-off/offers`,
  others_api: `/${BASE_ENV}/drop-off/others`,
};

export const toastErrorMessages = {
  unauthorizedErrorMessage: "Unauthorized access, token might be invalid",
  enterPhoneNumberErrorMessage: "Please enter phone number",
  enterValidPhoneNumberErrorMessage: "Please enter a valid phone number",
  offersNotAvailableErrorMessage: "Offers not available",
  somethingWentWrongErrorMessage: "Something went wrong..",
  offerDetailsErrorMessage: "Unable to proceed further due to KYC failure",
  offerDetailsNoOfferErrorMessage:
    "No offer Available due to eligibility not met",
  welcomeScreenUnthorizedErrorMessage: "You are not authorized to proceed.",
};
