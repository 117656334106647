import axios from "axios";
import { API_URL, BASE_ENV } from "Constant";
import { errorMessage } from "constant/AppConstant";
import { ErrorsCallback } from "interface";

export interface SubSubCategory {
  "subSub-Category": string;
  subCatCode: number;
  enumValue: string;
}

export interface SubCategory {
  subCategory: string;
  "subSub-Categories": SubSubCategory[];
}

export interface Category {
  category: string;
  industryCategory: string;
  subCategories: SubCategory[];
}

interface ApiResponse {
  categories: Category[];
}

type SuccessCallback = (data: ApiResponse) => void;

export const getApiTicket = (
  token: string,
  success: SuccessCallback,
  error: ErrorsCallback
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${API_URL}/${BASE_ENV}/static/issue-categories/v1`, config)
    .then((resp) => {
      if (resp.status === 201 || resp.status === 200) {
        success(resp.data);
      } else {
        error(errorMessage);
      }
    });
};
