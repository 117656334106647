import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaCamera } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { RootState } from "store/types/store";
import { API_ENDPOINTS, API_URL } from "Constant";
import { errorMessage } from "constant/AppConstant";
import { InitialValues } from "components/Tickets/types";
import Loader from "components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import "components/OffersDetailsShow/OffersDetailsShow.css";
import {
  Category,
  getApiTicket,
  SubCategory,
  SubSubCategory,
} from "api/TIcketRaiseView";

const Root = styled("div")({
  backgroundColor: "white",
  height: "100vh",
});

const Content = styled("div")({
  padding: "20px",
});

// const StyledTextField = styled(TextField)({
//   margin: "10px 0",
// });

// const DateField = styled(Box)({
//   margin: "10px 0",
//   width: "100%",
//   padding: "12px",
//   borderRadius: "10px",
// });

const validationSchema = Yup.object({
  selectCategory: Yup.string().required("Category is required"),
  selectSubCategory: Yup.string().required("Sub category is required"),
  selectSubSubCategory: Yup.string().required("Sub sub category is required"),
  shortDesc: Yup.string().required("Short description is required"),
  elaborateIssue: Yup.string().required("Elaborate issue in 150 characters"),
});

const TicketRaiseView = () => {
  const { loan_status_json, myloanIndex, loansData, phoneNumber, token } =
    useSelector((state: RootState) => state.auth);
  const [dropDownList, setDropDownList] = useState<Category[] | null>(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [, setSelectedSubCategory] = useState("");
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState("");
  const [dropDownSubCategoriesList, setDropDownSubCategoriesList] = useState<
    SubCategory[] | null
  >(null);
  const [dropDownSubSubCategoriesList, setDropDownSubSubCategoriesList] =
    useState<SubSubCategory[] | null>(null);
  const [shortDescription, setShortDescription] = useState("");
  const [elaborateIssue, setElaborateIssue] = useState("");
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer>("");
  const [images, setsimages] = useState<File[]>([]);
  const [isloading, setisloading] = useState(false);

  const initialValues: InitialValues = {
    selectCategory: "",
    selectSubCategory: "",
    selectSubSubCategory: "",
    shortDesc: "",
    elaborateIssue: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      Submit();
    },
  });

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        setSelectedImage(reader.result as string | ArrayBuffer);
      };

      reader.readAsDataURL(file);
      const temp: File[] = [...images];
      temp.push(file);
      setsimages(temp);
    }
  };

  const uploadImage = async () => {
    setisloading(true);
    const jsonData = {
      on_status: JSON.parse(loan_status_json),
      category: selectedCategory,
      sub_category: selectedSubSubCategory,
      description: {
        short_desc: shortDescription,
        Long_desc: elaborateIssue,
        additional_desc: {
          url: "https://buyerapp.com/additonal-details/desc.txt",
          content_type: "text/plain",
        },
        images: [],
      },
    };
    const formData = new FormData();
    formData.append("files", images[0]);
    formData.append("issue", JSON.stringify(jsonData));
    const selectedLoanIndex = myloanIndex;
    const loans = JSON.parse(loansData);
    const single_selected_loan = loans[selectedLoanIndex];

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API_URL + API_ENDPOINTS.upload_igm_issue,
      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        txn_id: single_selected_loan.context?.transaction_id,
        contact_number: phoneNumber,
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    axios
      .request(config)
      .then(() => {
        toast.success("Ticket raised successfully!");
      })
      .catch((error) => {
        toast.error(errorMessage || error);
      })
      .finally(() => setisloading(false));
  };

  const handleCategoryChange = (e: SelectChangeEvent<string>) => {
    setSelectedCategory(e.target.value);
    const subcategories = dropDownList?.find(
      (cat) => cat.category === e.target.value,
    );
    setDropDownSubCategoriesList(subcategories?.subCategories as SubCategory[]);
  };

  const handleSubCategoryChange = (e: SelectChangeEvent<string>) => {
    setSelectedSubCategory(e.target.value);
    const filteredCategory = dropDownList?.find(
      (cat) => cat.category === selectedCategory,
    );
    const subcategory = filteredCategory?.subCategories.find(
      (item) => item.subCategory.trim() === e?.target?.value?.trim(),
    );
    if (subcategory) {
      setDropDownSubSubCategoriesList(subcategory["subSub-Categories"]);
    }
  };

  const Submit = async () => {
    await uploadImage();
    formik.resetForm();
    setSelectedImage("");
    setsimages([]);
  };

  useEffect(() => {
    setisloading(true);
    getdropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getdropdown = async () => {
    getApiTicket(
      token,
      (response) => {
        if (response != null) {
          if (response.categories != null && response.categories.length > 0) {
            setDropDownList(response.categories || []);
          }
        }
      },
      (error) => {
        toast.error(error);
      },
    );
    setisloading(false);
  };

  return (
    <Root>
      <Content>
        <div
          style={{
            margin: "10px 0",
            padding: "10px",
            backgroundColor: "#E0E0E0",
            textAlign: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{ fontSize: "15px", color: "#000000", fontWeight: "bold" }}
            >
              <BsFillExclamationTriangleFill
                size={25}
                style={{ marginRight: "5px" }}
              />
              Raise an issue
            </div>
          </div>
        </div>
        {isloading ? (
          <Loader />
        ) : (
          <div style={{ padding: "20px" }}>
            <h6 style={{ fontWeight: "bold" }}>Issue Category</h6>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label1">
                Select Issue Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                label="Select Issue Category"
                variant="outlined"
                fullWidth
                name="selectCategory"
                value={formik.values.selectCategory}
                onChange={(e) => {
                  handleCategoryChange(e);
                  formik.handleChange(e);
                }}
                style={{ borderRadius: "50px" }}
              >
                <MenuItem value="">Select Category</MenuItem>
                {dropDownList?.map((category, index) => (
                  <MenuItem key={index} value={category.category}>
                    {category.category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <small className="text-danger">
              {formik.errors.selectCategory}
            </small>

            <h6 className="mt-3" style={{ fontWeight: "bold" }}>
              Issue Sub-Category
            </h6>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label2">
                Select Issue Sub-Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label2"
                label="Select Issue Sub Category"
                variant="outlined"
                fullWidth
                name="selectSubCategory"
                placeholder="Employment Type"
                value={formik.values.selectSubCategory}
                onChange={(e) => {
                  handleSubCategoryChange(e);
                  formik.handleChange(e);
                }}
                style={{ borderRadius: "50px" }}
              >
                <MenuItem value="">Select Subcategory</MenuItem>
                {dropDownSubCategoriesList?.map((subCategories, index) => (
                  <MenuItem key={index} value={subCategories.subCategory}>
                    {subCategories.subCategory}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <small className="text-danger">
              {formik.errors.selectSubCategory}
            </small>

            <h6 className="mt-3" style={{ fontWeight: "bold" }}>
              Issue Sub-Sub-Category
            </h6>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label3">
                Select Issue SubSub-Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label3"
                label="Select Issue Sub Sub Category"
                variant="outlined"
                fullWidth
                name="selectSubSubCategory"
                placeholder="Employment Type"
                value={formik.values.selectSubSubCategory}
                onChange={(e) => {
                  setSelectedSubSubCategory(e.target.value);
                  formik.handleChange(e);
                }}
                style={{ borderRadius: "50px" }}
              >
                <MenuItem value="">Select SubSubcategory</MenuItem>
                {dropDownSubSubCategoriesList?.map((subCategory, index) => (
                  <MenuItem key={index} value={subCategory["enumValue"]}>
                    {subCategory["subSub-Category"]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <small className="text-danger">
              {formik.errors.selectSubSubCategory}
            </small>

            <h6 className="mt-3" style={{ fontWeight: "bold" }}>
              Short Descripton
            </h6>
            <TextField
              label="Short Description"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setShortDescription(e.target.value);
                formik.handleChange(e);
              }}
              name="shortDesc"
            />
            <small className="text-danger">{formik.errors.shortDesc}</small>

            <h6 className="mt-3" style={{ fontWeight: "bold" }}>
              Please elaborate the issue
            </h6>
            <TextField
              label="please elaborate the issue"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setElaborateIssue(e.target.value);
                formik.handleChange(e);
              }}
              name="elaborateIssue"
              multiline
              rows={4}
              maxRows={5}
              inputProps={{
                maxLength: 150,
              }}
            />
            <small className="text-danger">
              {formik.errors.elaborateIssue}
            </small>
            <div>
              <div>
                <div className="d-flex justify-content-end pt-2">
                  <input
                    type="file"
                    accept="image/*"
                    id="image"
                    onChange={handleImageChange}
                    hidden
                  />
                  <label
                    style={{
                      background: "#3946aa",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    className="p-2"
                    htmlFor="image"
                  >
                    <FaCamera fontSize={"20px"} />
                  </label>
                </div>
                {selectedImage && (
                  <div>
                    <h3>Selected Image:</h3>
                    <img
                      src={selectedImage as string}
                      alt="Selected"
                      style={{ maxWidth: "100%", maxHeight: "400px" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <button
              onClick={() => formik.handleSubmit()}
              style={{
                backgroundColor: "#3946aa",
                color: "white",
                fontSize: "17px",
                padding: "10px 20px",
                borderRadius: "20px",
                border: "none",
                marginTop: "20px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              Submit
            </button>
          </div>
        )}
      </Content>
    </Root>
  );
};

export default TicketRaiseView;
