import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/types/store";
import { setIndexData } from "store/slices/authSlice";
import { fetchLoanTypesData } from "store/slices/selectOfferSlice";
import "components/SelectOffers/SelectOffers.css";
import "react-toastify/dist/ReactToastify.css";

const SelectOffers = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { showlistList } = useSelector(
    (state: RootState) => state.selectOffers,
  );

  useEffect(() => {
    dispatch(fetchLoanTypesData({ navigate }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleOffersDetailsShow = (index: number) => {
    dispatch(
      setIndexData({
        index: index,
      }),
    );
    navigate("/OffersDetailsShow");
  };

  return (
    <div className="select-offers-view-container">
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>{"Offers"}</span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        {showlistList?.length > 0 ? (
          showlistList.map((offer, index) => (
            <div
              key={index}
              className="offer-item"
              onClick={() => handleOffersDetailsShow(index)}
            >
              <div className="offer-info mb-2">
                <div className="d-flex align-items-center gap-2">
                  <div style={{ width: "15px" }}>
                    <img
                      src={offer.bankImage}
                      className="bank-logo w-100"
                      alt="bank-img"
                    />
                  </div>
                  <span
                    className="bank-name fw-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {offer.bankname}
                  </span>
                </div>
                <span style={{ cursor: "pointer" }}>
                  <IoIosArrowForward color="black" size={25} />
                </span>
              </div>
              <div className="offer-details">
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Loan
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.bankLoan}
                  </span>
                </div>
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Interest
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.bankInterestRate}
                  </span>
                </div>
              </div>
              <div className="offer-details">
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Tenure
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.loan_duration}
                  </span>
                </div>
                <div className="offer-detail">
                  <span className="fw-light" style={{ fontSize: "15px" }}>
                    Monthly EMI
                  </span>
                  <span className="fw-bold" style={{ fontSize: "15px" }}>
                    {offer.bankPeriod}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ position: "relative", height: "50vh" }}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#3946aa", "#3946aa", "#3946aa", "#3946aa", "#3946aa"]}
              />
              <p>Please wait...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectOffers;
