import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "store/store";
import { setBankOfferData } from "store/slices/authSlice";
import right_holder from "assets/images/right_holder.png";

const WelcomeView = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const handleBankOffers = () => {
    navigate("/SelectOffers");
  };

  useEffect(() => {
    dispatch(setBankOfferData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div style={{ position: "relative" }}>
        <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              height: "150px",
              margin: "auto",
            }}
          >
            <img className="w-100" src={right_holder} alt="Right Holder" />
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{ fontSize: "25px", color: "#000000", fontWeight: "bold" }}
            >
              Information shared!
            </h1>
          </div>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "14px",
                color: "#7F7F7F",
                fontWeight: "normal",
              }}
            >
              Online information sharing to get loan offers from lenders is
              complete. Proceed to request loan offers.
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-btn">
        <button
          onClick={handleBankOffers}
          style={{
            width: "100%",
            backgroundColor: "#3946aa",
            color: "white",
            border: "none",
            borderRadius: "10px",
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default WelcomeView;
