/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import axios from "axios";
import { store } from "store/store";
import { updateToken } from "store/slices/authSlice";
import {
  API_URL,
  BASE_ENV,
  PASSWORD,
  REACT_APP_REALM,
  toastErrorMessages,
} from "Constant";

export const Secured = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  },
});

Secured.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const token = state.auth.token;
    const expirationTime = parseInt(String(state.auth.expirationTime), 10);
    const currentTime = new Date().getTime();
    if (currentTime >= expirationTime) {
      try {
        const password = PASSWORD;
        let requestBody;
        if (state.auth.clientID === "NBT_Saathi") {
          requestBody = {
            user_name: "nbt saathi",
            password: password,
            realm: "NBTSaathi",
            client_id: "NBT_Saathi",
          };
        } else if (state.auth.clientID === "nbt") {
          requestBody = {
            user_name: "nbt",
            password: password,
            realm: REACT_APP_REALM,
            client_id: "nbt",
          };
        }

        const response = await axios.post(
          `${API_URL}/${BASE_ENV}/auth/login`,
          requestBody,
        );
        const newAccessToken = response.data.access_token;
        const newExpiresIn = response.data.expires_in;
        const newExpirationTime = new Date().getTime() + newExpiresIn * 1000;
        store.dispatch(
          updateToken({
            token: newAccessToken,
            expirationTime: newExpirationTime,
          }),
        );
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;
      } catch (error) {
        console.error("Error refreshing token:", error);
        return Promise.reject(new Error("Token refresh failed"));
      }
    } else {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Secured.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error(toastErrorMessages.unauthorizedErrorMessage);
    }

    return Promise.reject(error);
  },
);
