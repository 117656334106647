import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { RootState } from "store/types/store";
import { AppDispatch } from "store/store";
import { fetchLoanTypes } from "store/slices/loanTypeSlice";
import { ColorRing } from "react-loader-spinner";

const SelectLoanType = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { loanTypes, status, error } = useSelector(
    (state: RootState) => state.loanType
  );

  useEffect(() => {
    dispatch(fetchLoanTypes());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`);
    }
  }, [error]);

  return (
    <div>
      <div style={{ backgroundColor: "#3946aa", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <IoIosArrowBack color="white" size={25} />
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <span style={{ color: "white", fontSize: "17px" }}>
              Select Loan Type
            </span>
          </div>
          <div style={{ cursor: "pointer" }}>
            <i className="fas fa-info-circle" style={{ color: "white" }}></i>
          </div>
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        <h2>Select Loan Type</h2>
        <p>Search & apply for collateral free loans across different lenders</p>
        <div>
          {status === "loading" ? (
            <div style={{ position: "relative", height: "40vh" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#3946aa",
                    "#3946aa",
                    "#3946aa",
                    "#3946aa",
                    "#3946aa",
                  ]}
                />
                <p>Please wait...</p>
              </div>
            </div>
          ) : status === "failed" ? (
            <div style={{ color: "red" }}>{error}</div>
          ) : loanTypes.length > 0 ? (
            loanTypes.map((loanType, index) => (
              <div key={index}>
                <Link to="/IndividualInformation">
                  <button
                    style={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      padding: "20px 20px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "none",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                  >
                    {loanType.name}
                  </button>
                </Link>
              </div>
            ))
          ) : (
            <div>No loan types available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectLoanType;
