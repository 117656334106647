import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BASE_ENV, API_URL, REACT_APP_REALM } from "Constant";
import {
  LoginUserResponse,
  LoginUserPayload,
  AuthState,
} from "store/types/slice/AuthSlice";

export const loginUser = createAsyncThunk<LoginUserResponse, LoginUserPayload>(
  "auth/loginUser",
  // eslint-disable-next-line no-unused-vars
  async ({ clientID, agentID, apiKey, password }, { rejectWithValue }) => {
    let requestBody;
    agentID;
    apiKey;
    if (clientID === "NBT_Saathi") {
      requestBody = {
        user_name: "nbt saathi",
        password: password,
        realm: "NBTSaathi",
        client_id: "NBT_Saathi",
      };
    } else if (clientID === "nbt") {
      requestBody = {
        user_name: "nbt",
        password: password,
        realm: REACT_APP_REALM,
        client_id: "nbt",
      };
    }

    const response = await fetch(`${API_URL}/${BASE_ENV}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      return rejectWithValue(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  },
);

const initialState: AuthState = {
  agentID: "",
  clientID: "",
  apiKey: "",
  accessToken: "",
  token: "",
  expirationTime: null,
  showButton: false,
  status: "idle",
  error: null,
  googleWindow: null,
  applyPhoneNumber: "",
  data: null,
  tx_id: null,
  ctn_num: null,
  url: null,
  index: 0,
  bankOfferData: "",
  minLoanAmount: "",
  offers_map_json: "",
  kyc_url: "",
  bank_name: "",
  bpp_id: "",
  add_bank_json: null,
  final_new_disbusment_json: "",
  loan_status_json: "",
  myloanIndex: 0,
  phoneNumber: "",
  transact_id: "",
  loansData: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    resetBankOfferData: (state) => {
      state.bankOfferData = "";
    },
    setApplyPhoneNumber: (state, action: PayloadAction<string>) => {
      state.applyPhoneNumber = action.payload;
    },
    setQueryParams: (
      state,
      action: PayloadAction<{
        agentID: string;
        clientID: string;
        apiKey: string;
      }>,
    ) => {
      state.agentID = action.payload.agentID;
      state.clientID = action.payload.clientID;
      state.apiKey = action.payload.apiKey;
    },
    setShowButton: (state, action: PayloadAction<boolean>) => {
      state.showButton = action.payload;
    },
    // openGoogleWindow: (state) => {
    //   const newWindow = window.open("https://www.google.com", "_blank");
    //   state.googleWindow = newWindow;
    // },
    closeGoogleWindow: (state) => {
      if (state.googleWindow && !state.googleWindow.closed) {
        state.googleWindow.close();
        state.googleWindow = null;
      }
    },
    updateToken: (state, action) => {
      state.token = action.payload.token;
      state.expirationTime = action.payload.expirationTime;
    },
    setAuthData: (state, action) => {
      state.data = action.payload.data;
      state.tx_id = action.payload.tx_id;
      state.ctn_num = action.payload.ctn_num;
      state.url = action.payload.url;
    },
    setIndexData: (state, action) => {
      state.index = action.payload.index;
    },
    setBankOfferData: (state, action) => {
      state.bankOfferData = action.payload;
    },
    setMinLoanAmount: (state, action) => {
      state.minLoanAmount = action.payload;
    },
    setOffersMapData: (state, action) => {
      state.offers_map_json = action.payload;
    },
    setKycUrlData: (state, action) => {
      state.kyc_url = action.payload;
    },
    setBankNameData: (state, action) => {
      state.bank_name = action.payload;
    },
    setBppData: (state, action) => {
      state.bpp_id = action.payload;
    },
    setAddBankData: (state, action) => {
      state.add_bank_json = action.payload;
    },
    setNewDisbusmentData: (state, action) => {
      state.final_new_disbusment_json = action.payload;
    },
    setLoanStatusData: (state, action) => {
      state.loan_status_json = action.payload;
    },
    setTransactId: (state, action) => {
      state.transact_id = action.payload;
    },
    setPhoneNumberData: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setLoansData: (state, action) => {
      state.loansData = action.payload;
    },
    setLoanIndex: (state, action) => {
      state.myloanIndex = action.payload;
    },
    setTransactionId: (state, action) => {
      state.tx_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.accessToken = action.payload.access_token;
        state.token = action.payload.access_token;
        state.expirationTime =
          new Date().getTime() + action.payload.expires_in * 1000;
        state.showButton = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
        state.showButton = false;
      });
  },
});

export const {
  setQueryParams,
  setShowButton,
  resetBankOfferData,
  closeGoogleWindow,
  setApplyPhoneNumber,
  updateToken,
  setAuthData,
  setBankOfferData,
  setMinLoanAmount,
  setOffersMapData,
  setKycUrlData,
  setBankNameData,
  setBppData,
  setIndexData,
  setAddBankData,
  setNewDisbusmentData,
  setLoanStatusData,
  setTransactId,
  setPhoneNumberData,
  setLoansData,
  setLoanIndex,
  resetState,
  setTransactionId,
} = authSlice.actions;
export const authInitialState = (state: { auth: AuthState }) => state.auth;
export const selectAuth = (state: { auth: AuthState }) => state.auth;
export const selectTxId = (state: { auth: AuthState }) => state.auth.tx_id;
export const selectCtnNum = (state: { auth: AuthState }) => state.auth.ctn_num;
export const selectApplyPhoneNumber = (state: { auth: AuthState }) =>
  state.auth.applyPhoneNumber;
export const selectToken = (state: { auth: AuthState }) => state.auth.token;
export const agentID = (state: { auth: AuthState }) => state.auth.agentID;
export const clientID = (state: { auth: AuthState }) => state.auth.clientID;
export const apiKey = (state: { auth: AuthState }) => state.auth.apiKey;
export const selectBankOfferData = (state: { auth: AuthState }) =>
  state.auth.bankOfferData;
export default authSlice.reducer;
